<div class="container-fluid">
  <div class="row">
    <main class="col">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        Afficher les documents
      </div>
      <div class="mat-elevation-z8 margin-bottom-container">
        <table mat-table [dataSource]="dataSource" >
      
          <!-- Name Column -->
          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef> Nom du lieu </th>
            <td mat-cell *matCellDef="let row"> {{row.Name}} </td>
          </ng-container>
      
          <!-- Lat Column -->
          <ng-container matColumnDef="MonthOfMeasure">
            <th mat-header-cell *matHeaderCellDef> Numéro du mois </th>
            <td mat-cell *matCellDef="let row"> {{row.MonthOfMeasure}} </td>
          </ng-container>

          <!-- Lon Column -->
          <ng-container matColumnDef="YearOfMeasure">
            <th mat-header-cell *matHeaderCellDef> Année </th>
            <td mat-cell *matCellDef="let row"> {{row.YearOfMeasure}} </td>
          </ng-container>

          <!-- Type Column -->
          <ng-container matColumnDef="MarkerID">
            <th mat-header-cell *matHeaderCellDef> MarkerID </th>
            <td mat-cell *matCellDef="let row"> {{row.MarkerID}} </td>
          </ng-container>

          <!-- Type Column -->
          <ng-container matColumnDef="PathToFile">
            <th mat-header-cell *matHeaderCellDef> Empl. du fichier </th>
            <td mat-cell *matCellDef="let row"> {{row.PathToFile}} </td>
          </ng-container>

          <!-- Edit Column -->
          <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button (click)="editDocument(row, docmodal)">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Delete Column -->
          <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <button class="mib del" mat-icon-button (click)="deleteDocument(row)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                        showFirstLastButtons 
                        aria-label="Selectionner la page des marqueurs">
        </mat-paginator>
      </div>
      
      <div class="col-md-2">
        <button  mat-raised-button color="primary" (click)="addDoc(docmodal)">
          <i class="material-icons">add</i> Ajouter
        </button>
      </div>
    </main>
  </div>
</div>

<ng-template #docmodal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{action}} un document</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
      <div class="form-group full-width-input">
        <label>Nom du lieu</label>
        <select class="form-control"
                formControlName="name"
                required>
          <option *ngFor="let place of places" value="{{place.PlaceID}}">{{place.Name}}</option>
        </select>
      </div>
      <div class="form-group full-width-input">
        <label>Point du lieu</label>
        <select class="form-control"
                formControlName="marker"
                required>
          <option *ngFor="let m of markers" value="{{m.MarkerID}}">{{m.Name}}</option>
        </select>
      </div>
      <div class="form-group full-width-input">
        <label>Année</label>
        <input class="form-control"
              type="number"
              formControlName="year">
      </div>
      <div class="form-group full-width-input">
        <label>Mois (numéro du mois, 13 = annuel)</label>
        <input class="form-control" type="number" formControlName="month">
      </div>
      <div class="form-group full-width-input" *ngIf="action != 'Modifier'">
        <app-file-upload (fileNameEvent)="fileName($event)"></app-file-upload>
      </div>
      <div class="button-row">
        <button type="button" class="btn btn-primary" (click)="modalCallback()">Ajouter</button>
        <button type="button" class="btn btn-light" (click)="onCancel()">Annuler</button>
      </div>
    </form>
  </div>
</ng-template>