import { Component, Input, OnInit } from '@angular/core';
import { ServerService } from '@app/service/server.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-noise',
  templateUrl: './noise.component.html',
  styleUrls: ['./noise.component.css']
})
export class NoiseComponent implements OnInit {

  @Input() locName : string;
  @Input() markerId: number;

  images : any;
  graphs: any;
  legend: any[] = [];

  constructor(
    private server: ServerService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadPic();
  }

  async loadPic() {
    const img = await this.server.getNoiseDocumentsByMarkerId(this.markerId);
    let n: any[] = [];
    let g: any[] = [];
    let l: any[] = [];

    for(let i of img){
      if(i.Type === "image") {
        n.push(i.PathToFile);
        l.push(i.Legend);
      } else {
        g.push(i.PathToFile);
      }
      
    }
    this.images = n.map((n) => `${environment.serverPublic}/${n}`);
    this.graphs = g.map((n) => `${environment.serverPublic}/${n}`);
    this.legend = l.map((l) => `${l}`);
  }
}
