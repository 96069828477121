<div class="btn-group-vertical">
  <button
    class="btn btn-sm btn-outline-primary mr-2"
    (click)="submitEvent('TODAY')"
  >
    {{ 'STAT.TODAY' | translate }}
  </button>
  <button
    class="btn btn-sm btn-outline-primary mr-2"
    (click)="submitEvent('YESTERDAY')"
  >
    {{ 'STAT.YESTERDAY' | translate }}
  </button>
  <button
    class="btn btn-sm btn-outline-primary mr-2"
    (click)="submitEvent('THIS_WEEK')"
  >
    {{ 'STAT.THIS_WEEK' | translate }}
  </button>
  <button
    class="btn btn-sm btn-outline-primary mr-2"
    (click)="submitEvent('LAST_WEEK')"
  >
    {{ 'STAT.LAST_WEEK' | translate }}
  </button>
  <button
    class="btn btn-sm btn-outline-primary mr-2"
    (click)="submitEvent('THIS_MONTH')"
  >
    {{ 'STAT.THIS_MONTH' | translate }}
  </button>
  <button
    class="btn btn-sm btn-outline-primary mr-2"
    (click)="submitEvent('LAST_MONTH')"
  >
    {{ 'STAT.LAST_MONTH' | translate }}
  </button>
</div>
