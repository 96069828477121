import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ServerService } from '@app/service/server.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent {

  @Input() requiredFileType: string;
  @Output() fileNameEvent = new EventEmitter<File>();

  fileName = '';

  constructor(private server: ServerService) {}

  onFileSelected(event) {
    const file:File = event.target.files[0];
  
    if (file) {
      this.fileName = file.name;
      this.fileNameEvent.emit(file);
    }
  }
}
