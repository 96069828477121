import { Injector, NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { MapComponent } from '@app/map/map.component';
import { StatComponent } from '@app/stat/stat.component';
import { OktaAuthGuard, OktaAuthService, OktaCallbackComponent } from '@okta/okta-angular';

import { LoginComponent } from './login/login.component';
import { PlaceComponent } from './place/place.component';
import { MarkerComponent } from './marker/marker.component';
import { DocumentComponent } from './document/document.component';
import { AdminNoiseComponent } from './admin-noise/admin-noise.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminDensityComponent } from './admin-density/admin-density.component';

export function onAuthRequired(oktaAuth: OktaAuthService, injector: Injector): void {
  const router = injector.get(Router);
  router.navigate(['/login']);
}

const routes: Routes = [
  { path: 'map', component: MapComponent },
  { path: '', redirectTo: '/map', pathMatch: 'full' },
  { path: 'callback', component: OktaCallbackComponent },
  { path: 'login', component: LoginComponent },
  { path: 'admin', component: AdminHomeComponent, canActivate: [OktaAuthGuard], data: {onAuthRequired} },
  { path: 'places', component: PlaceComponent, canActivate: [OktaAuthGuard], data: {onAuthRequired} },
  { path: 'markers', component: MarkerComponent, canActivate: [OktaAuthGuard], data: {onAuthRequired} },
  { path: 'documents', component: DocumentComponent, canActivate: [OktaAuthGuard], data: {onAuthRequired} },
  { path: 'noises', component: AdminNoiseComponent, canActivate: [OktaAuthGuard], data: {onAuthRequired} },
  { path: 'densities', component: AdminDensityComponent, canActivate: [OktaAuthGuard], data: {onAuthRequired} }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot( routes )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}

