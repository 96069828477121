import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ServerService } from '@app/service/server.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AdminDensity } from '@app/model/admin-density';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-admin-density',
  templateUrl: './admin-density.component.html',
  styleUrls: ['./admin-density.component.css']
})
export class AdminDensityComponent implements OnInit {

  //The name for the column of the display array
  displayedColumns: string[] = ['RoadName', 'RoadSectionID', 'Year', 'Density', 'Edit', 'Delete'];
  dataSource = new MatTableDataSource<AdminDensity>();   //Use to populate the array
  action: string;                               //For updating the name of the modal form

  roads: any[] = [];                           //Store info about the places

  currentAdminDensity: AdminDensity = new AdminDensity();
  loading: boolean = false;                     //De-activate the loading display

  form: FormGroup;                              //The form in the modal
  modalRef: BsModalRef;                         //Store ref to the modal form
  modalCallback: () => void;                    //Call back for the modal form

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private fb: FormBuilder,
              private modalService: BsModalService,
              private server: ServerService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: this.currentAdminDensity.RoadName,
      year: this.currentAdminDensity.Year,
      density: this.currentAdminDensity.Density,
    });
    this.refresh();
  }

  async refresh() {
    this.loading = true;
    const data = await this.server.getDensities();
    this.dataSource.data = data;
    this.roads = data;
    this.loading = false;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  addDensity(template: any) {
    this.action = "Ajouter"
    this.currentAdminDensity = {DensityID: null, Year: null, Density: null, RoadSectionID: null, RoadName: ''};
    this.updateForm();
    this.modalCallback = this.createAdminDensity.bind(this);
    this.modalRef = this.modalService.show(template);
  }

  async createAdminDensity() {
    //Get values from the form
    const NEW_ADMIN_DENSITY: AdminDensity = {
      RoadSectionID: this.form.get('name').value,
      Year: this.form.get('year').value,
      Density: this.form.get('density').value,
    };
    console.log("RoadSectionId", NEW_ADMIN_DENSITY.RoadSectionID);
    this.modalRef.hide();
    await this.server.createDensity(NEW_ADMIN_DENSITY); //Send data to backend    }
    await this.refresh();
  }

  editDensity(density: AdminDensity, template: any) {
    this.action = "Modifier";
    this.currentAdminDensity = density;
    this.updateForm();
    this.modalCallback = this.updateDensity.bind(this);
    this.modalRef = this.modalService.show(template);
  }

  private updateForm() {
    /*const place = this.places.find(x => x.Name == this.currentDocument.Name);
    if(this.currentAdminDensity.Name != null)
      this.currentAdminDensity.PlaceID = place.PlaceID;*/
    console.log("Density update", this.currentAdminDensity);
    this.form.setValue({
      name: this.currentAdminDensity.RoadSectionID,
      year: this.currentAdminDensity.Year,
      density: this.currentAdminDensity.Density,
    });
  }

  async updateDensity() {
    const DENSITY_DATA: AdminDensity = {
      DensityID: this.currentAdminDensity.DensityID,
      RoadSectionID: this.form.get('name').value,
      Year: this.form.get('year').value,
      Density: this.form.get('density').value,
    };
    console.log("Update Density", DENSITY_DATA)
    this.modalRef.hide();
    await this.server.updateDensity(DENSITY_DATA);
    await this.refresh();
  }

  async deleteDensity(density: AdminDensity) {
    this.loading = true;
    if (confirm(`Etes-vous certain de vouloir effacer ${density.RoadName} ${density.RoadSectionID} ${density.Year}. Ceci est irrémédiable.`)) {
      await this.server.deleteDensity(density.DensityID);
    }
    await this.refresh();
  }

  onCancel() {
    this.modalRef.hide();
  }

}
