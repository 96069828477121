<section class="map-container">
    <div class="map-frame" [ngClass]="isMapInfoShow ? 'hide-dem-stuff' : 'show-dat-map'"> 
        <div id="terkep"></div>
        <!-- <div class="map-legend"> -->
        <!--     <b>Légende</b> -->
        <!--     <div class="legend__icon-container"> -->
        <!--         <i class="material-icons" style="color:red">place</i> -->
        <!--         <span class="legend-label"> -->
        <!--             : Traffic historique, -->
        <!--         </span> -->
        <!--     </div> -->
        <!--     <div class="legend__icon-container"> -->
        <!--         <i class="material-icons" style="color:green">place</i> -->
        <!--         <span class="legend-label"> -->
        <!--             : Bruit, -->
        <!--         </span> -->
        <!--     </div> -->
        <!--     <div class="legend__icon-container"> -->
        <!--         <i class="material-icons" style="color:purple">place</i> -->
        <!--         <span class="legend-label"> -->
        <!--             : Trafic mesuré par camera. -->
        <!--         </span> -->
        <!--     </div> -->
        <!-- </div> -->
    </div>
    <div class="map-info" [ngClass]="isMapInfoShow ? 'show-dem-info': 'hide-dem-stuff'">
        <div class="map-info-header">
            <div class="map-info__icon-container">
                <a mat-button (click)="onClose()">
                    <i class="material-icons">close</i>
                </a>
                <span class="map-info__label">
                    {{ locName }}
                </span>
            </div>
        </div>
        <div class="map-info-content" *ngIf='isStatShow.value'>
            <app-stat [el_input]="locId"></app-stat>
        </div>
        <div class="map-info-content" *ngIf='isPdfShow'>
            <app-file-explorer
                    [fileElements]="fileElements | async"
                    [path]="currentPath"
                    [canNavigateUp]="canNavigateUp"
                    (navigatedDown)="navigateToFolder($event)"
                    (navigatedUp)="navigateUp()"
                    (openFile)="openFile($event, pdfmodal)"
                    ></app-file-explorer>
        </div>
        <div class="map-info-content" *ngIf='isNoiseShow'>
            <app-noise [locName]="locName" [markerId]="markerId"></app-noise>
        </div>
    </div>
</section>

<ng-template #pdfmodal>
    <div class="modal-header">
      <h4 class="modal-title pull-left">PDF</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <pdf-viewer [src]="pdfSrc"
            [render-text]="true"
            [zoom-scale]="'page-width'"
            style="display: block; height: 100vh;"
        ></pdf-viewer>
    </div>
    <div>
        <button type="button"></button>
    </div>
</ng-template>
