import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OktaAuthService } from '@okta/okta-angular';
import { environment } from '../../environments/environment';
import { Place } from '../model/place';
import { Marker } from '../model/marker';
import { Document } from '@app/model/document';
import { NoiseDocument } from '@app/model/noise-document';
import { AdminDensity } from '@app/model/admin-density';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  constructor(private http: HttpClient, public oktaAuth: OktaAuthService) {
  }

  private async request(method: string, url: string, data?: any, responseType?: any) {
    const token = await this.oktaAuth.getAccessToken();

    const result = this.http.request(method, url, {
      body: data,
      responseType: responseType || 'json',
      observe: 'body',
      headers: {
	Authorization: `Bearer ${token}`,
	//'Content-Type': 'application/json',
      }
    });
    return new Promise<any>((resolve, reject) => {
      result.subscribe(resolve as any, reject as any);
    });
  }

  getPlaces() {
    return this.request('GET', `${environment.serverUrl}/places`);
  }

  getPlacesName() {
    return this.request('GET', `${environment.serverUrl}/placesByName`);
  }

  createPlace(place: Place) {
    return this.request('POST', `${environment.serverUrl}/place`, place);
  }

  updatePlace(place: Place) {
    return this.request('POST', `${environment.serverUrl}/place/${place.PlaceID}`, place);
  }

  deletePlace(place: Place) {
    return this.request('DELETE', `${environment.serverUrl}/place/${place.PlaceID}`);
  }

  /**** Markers *****/

  getMarkers() {
    return this.request('GET', `${environment.serverUrl}/markers`);
  }

  getMarkersByType(type: string) {
    return this.request('POST', `${environment.serverUrl}/markersByType/${type}`);
  }

  createMarker(marker: Marker) {
    return this.request('POST', `${environment.serverUrl}/marker`, marker);
  }

  updateMarker(marker: Marker) {
    return this.request('POST', `${environment.serverUrl}/marker/${marker.MarkerID}`, marker);
  }

  deleteMarker(marker) {
    return this.request('DELETE', `${environment.serverUrl}/marker/${marker.MarkerID}`);
  }

  /**** DOCUMENT ******/
  getDocuments() {
    return this.request('GET', `${environment.serverUrl}/documents`);
  }

  getDocumentsByMarkerID(id) {
    return this.request('GET', `${environment.serverUrl}/documents/${id}`);
  }

  getDocumentsByYears(id) {
    return this.request('GET', `${environment.serverUrl}/docyears/${id}`);
  }

  getDocumentsByMarkers(id, year) {
    return this.request('GET', `${environment.serverUrl}/docbyyears/${id}/${year}`)
  }

  createDocument(document) {
    console.log("createDocument service log", document);
    return this.request('POST', `${environment.serverUrl}/document`, document);
  }

  updateDocument(doc: Document) {
    console.log("update doc", doc);
    return this.request('POST', `${environment.serverUrl}/document/${doc.DocumentID}`, doc);
  }

  deleteDocument(docId) {
    console.log('createDocument ', document);
    return this.request('DELETE', `${environment.serverUrl}/document/${docId}`);
  }

  /******* NoiseDocument *******/
  getNoiseDocuments() {
    return this.request('GET', `${environment.serverUrl}/noisedocuments`);
  }

  getNoiseDocumentsByMarkerId(id:number) {
    return this.request('GET', `${environment.serverUrl}/noiseDocumentsByMarkerId/${id}`);
  }

  createNoiseDocument(document) {
    return this.request('POST', `${environment.serverUrl}/noisedocument`, document);
  }

  updateNoise(noise: NoiseDocument) {;
    return this.request('POST', `${environment.serverUrl}/noise/${noise.NoiseID}`, noise);
  }

  deleteNoise(noiseId) {
    return this.request('DELETE', `${environment.serverUrl}/noise/${noiseId}`);
  }

  /********* DENSITY ***********/
  getDensities() {
    return this.request('GET', `${environment.serverUrl}/densities`);
  }

  createDensity(density: AdminDensity) {
    return this.request('POST', `${environment.serverUrl}/density`, density);
  }

  updateDensity(density: AdminDensity) {;
    return this.request('POST', `${environment.serverUrl}/density/${density.DensityID}`, density);
  }

  deleteDensity(densityID) {
    return this.request('DELETE', `${environment.serverUrl}/density/${densityID}`);
  }
}
