<div class="app-container">
  <app-header></app-header>
  <div class="page-container">
    
    <nav class="app-nav"  *ngIf="isAuthenticated" (click)="isMenuOpen = !isMenuOpen" [ngClass]="isMenuOpen ? '': 'nav--collapsed'">
      
      <a class="nav__link" [routerLink]="'/admin'" routerLinkActive="active">
        <div class="nav__icon-container">
          <i class="material-icons">dashboard</i>
        </div>
        <span class="nav__label">
          Admin
        </span>
      </a>
      
      <a class="nav__link" [routerLink]="'/map'" routerLinkActive="active">
        <div class="nav__icon-container">
          <i class="material-icons">map</i>
        </div>
        <span class="nav__label">
          Carte
        </span>
      </a>
      
      <a class="nav__link" [routerLink]="'/places'" routerLinkActive="active">
        <div class="nav__icon-container">
          <i class="material-icons">location_searching</i>
        </div>
        <span class="nav__label">
          Lieux
        </span>
      </a>
  
      <a class="nav__link" [routerLink]="'/markers'" routerLinkActive="active">
        <div class="nav__icon-container">
          <i class="material-icons">place</i>
        </div>
        <span class="nav__label">
          Points de mesure
        </span>
      </a>
  
      <a class="nav__link" [routerLink]="'/documents'" routerLinkActive="active">
        <div class="nav__icon-container">
          <i class="material-icons">picture_as_pdf</i>
        </div>
        <span class="nav__label">
          Comptage
        </span>
      </a>
  
      <a class="nav__link" [routerLink]="'/noises'" routerLinkActive="active">
        <div class="nav__icon-container">
          <i class="material-icons">surround_sound</i>
        </div>
        <span class="nav__label">
          Bruits
        </span>
      </a>

      <a class="nav__link" [routerLink]="'/densities'" routerLinkActive="active">
        <div class="nav__icon-container">
          <i class="material-icons">traffic</i>
        </div>
        <span class="nav__label">
          Densité
        </span>
      </a>

      <a class="nav__link" (click)="logout()">
        <div class="nav__icon-container">
          <i class="material-icons">logout</i>
        </div>
        <span class="nav__label">
          Déconnexion
        </span>
      </a>
    </nav>
    <main class="main">
        <router-outlet></router-outlet>
    </main>
  </div>
  <app-footer></app-footer>
</div>
