import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class DensityServiceService {

  constructor(private http: HttpClient) { }

  getStateShapes() {
    return this.http.get('/assets/data/fribourg.json');
  }
}
