<div class="container-fluid">
  <section class="col">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      Afficher les lieux
    </div>

    <div class="mat-elevation-z8 margin-bottom-container">
      <table mat-table [dataSource]="dataSource" matSort>

        <!-- Name Column -->
        <ng-container matColumnDef="Name">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header>
            Nom
          </th>
          <td mat-cell *matCellDef="let place"> {{place.Name}} </td>
        </ng-container>

        <!-- Code Column -->
        <ng-container matColumnDef="Code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
          <td mat-cell *matCellDef="let place"> {{place.Code}} </td>
        </ng-container>

        <!-- Edit Column -->
        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let place">
            <button mat-icon-button 
                    (click)="editPlace(place, placemodal)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="Delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let place">
            <button class="mib del"
                    mat-icon-button
                    (click)="deletePlace(place)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10]"
                    showFirstLastButtons 
                    aria-label="Selectionner la page des lieux">
      </mat-paginator>
    </div>
    <div class="col-md-2">
      <button mat-raised-button color="primary"
              (click)="addPlace(placemodal)">
        <i class="material-icons">add</i> Ajouter
      </button>
    </div>
  </section>
</div>
<div class="loading" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>


<ng-template #placemodal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{action}} un lieu</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group full-width-input">
        <label>Nom du lieu</label>
        <input class="form-control" placeholder="Nom du lieu" formControlName="name" required>
      </div>
      <div class="form-group full-width-input">
        <label>Code</label>
        <input class="form-control" type="number" placeholder="ex. 300" formControlName="code">
      </div>
      <div class="button-row">
        <button type="button" class="btn btn-primary" (click)="modalCallback()">{{action}}</button>
        <button type="button" class="btn btn-light" (click)="onCancel()">Annuler</button>
      </div>
    </form>
  </div>
</ng-template>
