import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ServerService } from '@app/service/server.service';
import { Marker } from '@app/model/marker';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-marker',
  templateUrl: './marker.component.html',
  styleUrls: ['./marker.component.css']
})
export class MarkerComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['PlaceName', 'Latitude', 'Longitude', 'Type', 'Edit', 'Delete'];
  dataSource = new MatTableDataSource<any>();
  action: string;                               //For updating the name of the modal form

  places: any[] = [];
  currentMarker: Marker = new Marker();
  loading: boolean = false;

  form: FormGroup;
  modalRef: BsModalRef;
  modalCallback: () => void;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private fb: FormBuilder,
              private modalService: BsModalService,
              private server: ServerService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.currentMarker.PlaceID, Validators.required],
      latitude: [this.currentMarker.Latitude, Validators.required],
      longitude: [this.currentMarker.Longitude, Validators.required],
      type: this.currentMarker.Type
    });
    this.refresh();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async refresh() {
    this.loading = true;
    const data = await this.server.getMarkers();
    this.places = await this.server.getPlacesName();
    this.dataSource.data = data;
    this.loading = false;
  }

  addMarker(template: any) {
    this.action = "Ajouter";
    this.currentMarker = {MarkerID: null, Latitude: null, Longitude: null, Type: '', PlaceID: null};
    this.updateForm();
    this.modalCallback = this.createMarker.bind(this);
    this.modalRef = this.modalService.show(template);
  }

  async createMarker() {
    const NEW_MARKER: Marker = {
      Latitude: this.form.get('latitude').value,
      Longitude: this.form.get('longitude').value,
      Type: this.form.get('type').value,
      PlaceID: this.form.get('name').value,
    };
    this.modalRef.hide();
    await this.server.createMarker(NEW_MARKER);
    await this.refresh();
  }

  editMarker(marker: Marker, template: any) {
    this.action = "Modifier";
    this.currentMarker = marker;
    this.updateForm();
    this.modalCallback = this.updateMarker.bind(this);
    this.modalRef = this.modalService.show(template);
  }

  async updateMarker() {
    const MARKER_DATA : Marker = {
      MarkerID: this.currentMarker.MarkerID,
      Latitude: this.form.get('latitude').value,
      Longitude: this.form.get('longitude').value,
      Type: this.form.get('type').value,
      PlaceID: this.form.get('name').value,
    };
    this.modalRef.hide();
    await this.server.updateMarker(MARKER_DATA);
    await this.refresh();
  }

  private updateForm() {
    this.form.setValue({
      name: this.currentMarker.PlaceID,
      latitude: this.currentMarker.Latitude,
      longitude: this.currentMarker.Longitude,
      type: this.currentMarker.Type
    });
  }

  async deleteMarker(marker) {
    this.loading = true;
    if (confirm(`Etes-vous certain de vouloir effacer ${marker.Name}- ${marker.Type}. Ceci est irrémédiable.`)) {
      await this.server.deleteMarker(marker);
    }
    await this.refresh();
  }

  onCancel() {
    this.modalRef.hide();
  }
}
