import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { merge, Observable, of } from 'rxjs';
import { take, map, switchMap, mergeMap, groupBy, reduce, toArray } from 'rxjs/operators';
import dateformat from 'dateformat';

// While json is local
import * as camera_config_json from '../assets/camera_config.json';
import { getLocaleDateFormat } from '@angular/common';
import { flatten } from '@angular/compiler';
import { loadTranslations } from '@angular/localize';

//const BBDATA_URL: string = "/api";
const BBDATA_URL: string = "https://bbdata-admin.smartlivinglab.ch/api";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': "application/json",
    'Accept': "application/json",
    'bbuser': '117', // Ivan Baeriswyl user ID
    'bbtoken': 'c620cde6b722f66c2738ea29b8261244' // apikey
  })
};

@Injectable({
  providedIn: 'root'
})
export class BBDataService {

  camera_config;

  constructor( private httpService: HttpClient ) {
    this.camera_config = this.getCameraConfig();
  }


  // PRIVATE METHODS ---------------------------------------------------------

  private server_get(url: string): Observable<any> {
    const url_ = BBDATA_URL + url;

    return this.httpService.get(url_, httpOptions);
  }

  private server_post(url: string, data): Promise<any> {
    const url_ = BBDATA_URL + url;

    let body = JSON.stringify(data);

    return this.httpService.post(url_, body, httpOptions)
      .toPromise()
  }


  // ACCESSSIBLE METHODS -----------------------------------------------------

  getCameraConfig() {
    return <any>camera_config_json["default"];
  }

  getMockData(from_date, to_date) {
    var obj_id = 13481; // id of object Fribourg HEIA-FR Zone 1 VC1
    return new Promise(resolve => {
      this.server_get("/objects/" + obj_id.toString() + "/values?from=" + from_date +
       ((to_date) ? "&to=" + to_date : "")).pipe(
        take(1),
        groupBy(({object_id, timestamp, value: number}) => object_id),
        mergeMap((grp$) => grp$.pipe(reduce((acc, cur) => [...acc, cur], []))),
        //map(({object_id, timestamp, value}) => ({timestamp, value})),             //WORK IN PROGRESS -- using those Rxjs operators
      )
      .subscribe( (data) => {
        //console.log(dateformat(new Date("2020-12-12"), "isoUtcDateTime")); //Example on dateformat for UTC format
        resolve(data);
      })
    });
  }

  getAvailableLocations() {
   return this.getCameraConfig();
  }

  getInfosOfLocation(index: number) {
    if (index >= this.getAvailableLocations().length) {
      console.error("index out of bound exception in getInfosOfLocation");
      return null;
    }
    return this.getAvailableLocations()[index];
  }

  getTotalVehicles(location_info, zone_index: number, from_date, to_date) {
    let obj_ids = [];
    location_info.zones[zone_index].classifications.map( element => obj_ids.push(element.classification_id));

    return Promise.all(obj_ids.map(obj_id => this.getData(obj_id, from_date, to_date)))
    .then(
      (data_array) => {
        let flatten_array = [];
        data_array.forEach(array => { flatten_array = flatten_array.concat(array) });
        flatten_array = flatten_array.map(({objectId, timestamp, value}) => {
          return {
            timestamp: timestamp,
            value: value
          }
        });
        flatten_array = this.groupBy(flatten_array, "timestamp");
        let res = [];
        for(let y in flatten_array) {
          res.push({
              timestamp: y,
              value: flatten_array[y].reduce((acc, cur) => acc + cur["value"], 0)
            }
           );
        }
        return res;
      }
    );
  }


  getAvgSpeed(location_info, zone_index: number, from_date, to_date) {
    return this.getFirstLayerProperty(location_info, zone_index, 'speed_avg', from_date, to_date);
  }

  getSpeed85(location_info, zone_index: number, from_date, to_date) {
    return this.getFirstLayerProperty(location_info, zone_index, 'speed_85', from_date, to_date);
  }

  /* More generic function for VCx properties */
  getVCx(VCx, location_info, zone_index: number, from_date, to_date) {
    let VCs = location_info.zones[zone_index].classifications;
    let obj_ids = [];
    for (let v of VCs) { // find the object of interest and returns its id
      if (v.classification_name === VCx) {
        obj_ids = [v.classification_id];
        break;
      }
    }

    return Promise.all(obj_ids.map(obj_id => this.getData(obj_id, from_date, to_date)))
    .then(
      (data_array) => {
        let flatten_array = [];
        data_array.forEach(array => { flatten_array = flatten_array.concat(array) });
        flatten_array = flatten_array.map(({objectId, timestamp, value}) => {
          return {
            timestamp: timestamp,
            value: value
          }
        });
        flatten_array = this.groupBy(flatten_array, "timestamp");
        let res = [];
        for(let y in flatten_array) {
          res.push({
              timestamp: y,
              value: flatten_array[y].reduce((acc, cur) => acc + cur["value"], 0)
            }
           );
        }
        return res;
      }
    );
  }

  /* More generic function to retrieve first layer properties, such as speed_avg, speed85, etc. */
  getFirstLayerProperty(location_info, zone_index: number, property_name, from_date, to_date) {
    let obj_ids = [ location_info.zones[zone_index][property_name] ];

    return Promise.all(obj_ids.map(obj_id => this.getData(obj_id, from_date, to_date)))
    .then(
      (data_array) => {
        let flatten_array = [];
        data_array.forEach(array => { flatten_array = flatten_array.concat(array) });
        flatten_array = flatten_array.map(({objectId, timestamp, value}) => {
          return {
            timestamp: timestamp,
            value: value
          }
        });
        flatten_array = this.groupBy(flatten_array, "timestamp");
        let res = [];
        for(let y in flatten_array) {
          res.push({
              timestamp: y,
              value: flatten_array[y].reduce((acc, cur) => acc + cur["value"], 0) / flatten_array[y].length
            }
           );
        }
        return res;
      }
    );
  }

  groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };


  getData(obj_id, from_date, to_date) {
    if (!obj_id) return null;
    return new Promise(resolve => {
      this.server_get("/objects/" + obj_id.toString() + "/values?from=" + from_date +
       ((to_date) ? "&to=" + to_date : "")).pipe(
        mergeMap(r => r),
        map(({objectId, timestamp, value}) => (
          {
            objectId: objectId,
            timestamp: this.getRoundedDate(timestamp, 15),
            value: parseInt(value),
          })
        ),
        toArray()
      )
      .subscribe( (data) => {
        resolve(data);
      })
    });
  }

  // Workaround to apply function on a single property inside RxJS pipe
  embedFunc(row, func){
    row.timestamp = func(row.timestamp);
    return new Observable(row);
  }

  /* Round a date to xx minutes (default = 15 min) */
  getRoundedDate = (d, minutes=15) => {
    let date = new Date(d);
    let ms = 1000 * 60 * minutes; // convert minutes to ms
    let roundedDate = new Date(Math.round(date.getTime() / ms) * ms);

    return dateformat(roundedDate, "dd-mm-yyyy HH:MM");
  }
}
