<div class="container">
  <div class="row">
    <ngb-carousel *ngIf="images" class="col">
      <ng-template ngbSlide *ngFor="let image of images; let i=index">
        <div class="picsum-img-wrapper">
          <img [src]="image" class="d-block w-100" alt="Random first slide">
        </div>
        <div class="carousel-caption d-block w-50">
          <!--<h3>{{image.split('/').pop()}}</h3>-->
          <p>{{legend[i]}}</p>
        </div>
      </ng-template>
    </ngb-carousel>
    <div class="col">
      <div class="pt-4 pb-4">
        {{ 'NOISE.RESUME' | translate }}
      </div>
      <ul class="list-group">
        <li class="list-group-item">
          <a href="assets/noise_resume/bruit%20en%20fonction%20des%20distances.pdf" target="_blank">
            {{ 'NOISE.RESUME_DIST' | translate }}</a>
        </li>
        <li class="list-group-item">
          <a href="assets/noise_resume/mesures%20de%20bruit_Fribourg.pdf" target="_blank">{{ 'NOISE.RESUME_FR' | translate }}</a>
        </li>
        <li class="list-group-item">
          <a href="assets/noise_resume/mesures%20de%20bruit%20sur%20Fribourg%20et%20bulle.pdf" target="_blank">{{ 'NOISE.RESUME_FR_BU' | translate }}</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="row">
    <img [src]="graphs" class="d-block w-100" class="align-center">
  </div>
</div>

