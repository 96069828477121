import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServerService } from '@app/service/server.service';
import { MatPaginator } from '@angular/material/paginator';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MatTableDataSource } from '@angular/material/table';
import { NoiseDocument } from '@app/model/noise-document';
import { Marker } from '@app/model/marker';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-admin-noise',
  templateUrl: './admin-noise.component.html',
  styleUrls: ['./admin-noise.component.css']
})
export class AdminNoiseComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['Legend', 'MarkerID', 'PathToFile', 'Type', 'Edit', 'Delete'];
  dataSource = new MatTableDataSource<NoiseDocument>();

  markers: any[] = [];
  currentNoiseDocument: NoiseDocument = new NoiseDocument();
  loading: boolean = false;
  action: string;
  file: File;
  form: FormGroup;
  modalRef: BsModalRef;
  modalCallback: () => void;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private fb: FormBuilder,
              private modalService: BsModalService,
              private server: ServerService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      legend: this.currentNoiseDocument.Legend,
      marker: this.currentNoiseDocument.MarkerID,
      type: this.currentNoiseDocument.Type,
      document: this.currentNoiseDocument.PathToFile,
    });
    this.refresh();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async refresh() {
    this.loading = true;
    const data = await this.server.getNoiseDocuments();             //Get all the noise documents from the database
    this.markers = await this.server.getMarkersByType("bruit");     //Get the markers for select option in form
    this.dataSource.data = data;
    this.loading = false;
  }

  addNoise(template: any) {
    this.action = "Ajouter";
    this.currentNoiseDocument = {NoiseID: null, Legend: '', MarkerID: null, PathToFile: '', Type: ''};
    this.updateForm();
    this.modalCallback = this.createNoiseDocument.bind(this);
    this.modalRef = this.modalService.show(template);
  }

  async createNoiseDocument() {
    //Get values from the form
    const NEW_DOCUMENT = {
      markerId: this.form.get('marker').value,
      legend: this.form.get('legend').value,
      type: this.form.get('type').value
    };

    const marker = this.markers.find(x =>  x.MarkerID == NEW_DOCUMENT.markerId);

    console.log("marker", marker);
    //Create a form with all the data for saving in database + on file system
    const formData = new FormData();
    formData.append("thumbnail", this.file);
    formData.append("type", NEW_DOCUMENT.type);
    formData.append("marker", NEW_DOCUMENT.markerId);
    formData.append("legend", NEW_DOCUMENT.legend);
    formData.append("place", marker.Name);

    this.modalRef.hide();

    await this.server.createNoiseDocument(formData); //Send data to backend
    await this.refresh();
  }

  editNoise(noise: NoiseDocument, template: any) {
    this.action = "Modifier";
    console.log('Noise', noise);
    this.currentNoiseDocument = noise;
    this.updateForm();
    this.modalCallback = this.updateNoise.bind(this);
    this.modalRef = this.modalService.show(template);
  }

  private updateForm() {
    this.form.setValue({
      legend: this.currentNoiseDocument.Legend,
      marker: this.currentNoiseDocument.MarkerID,
      type: this.currentNoiseDocument.Type,
      document: null
    });
  }

  async updateNoise() {
    const NOISE_DATA: NoiseDocument = {
      NoiseID: this.currentNoiseDocument.NoiseID,
      Legend: this.form.get('legend').value,
      MarkerID: this.form.get('marker').value,
      Type: this.form.get('type').value
    };
    console.log('Noise DATA', NOISE_DATA)
    this.modalRef.hide();
    await this.server.updateNoise(NOISE_DATA);
    await this.refresh();
  }

  async deleteNoise(noise: NoiseDocument) {
    console.log("Noise", noise);
    this.loading = true;
    if (confirm(`Etez-vous certain de vouloir effacer ${noise.Legend}. Ceci est irreversible.`)) {
      await this.server.deleteNoise(noise.NoiseID);
    }
    await this.refresh();
  }

  fileName(file: File) {
    this.file = file;
  }

  onCancel() {
    this.modalRef.hide();
  }

}
