<div class="container-fluid">
  <section class="col">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      Afficher les documents pour le bruit
    </div>
    <div class="mat-elevation-z8 margin-bottom-container">
      <table mat-table [dataSource]="dataSource" matSort>
    
        <!-- Legend Column -->
        <ng-container matColumnDef="Legend">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Légende </th>
          <td mat-cell *matCellDef="let row"> {{row.Legend}} </td>
        </ng-container>

        <!-- Marker id Column -->
        <ng-container matColumnDef="MarkerID">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Marker id </th>
          <td mat-cell *matCellDef="let row"> {{row.MarkerID}} </td>
        </ng-container>

        <!-- Path To File Column -->
        <ng-container matColumnDef="PathToFile">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Empl. du fichier </th>
          <td mat-cell *matCellDef="let row"> {{row.PathToFile}} </td>
        </ng-container>

        <!-- Type To File Column -->
        <ng-container matColumnDef="Type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
          <td mat-cell *matCellDef="let row"> {{row.Type}} </td>
        </ng-container>

        <!-- Edit Column -->
        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="editNoise(row, noisemodal)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="Delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button class="mib del" mat-icon-button (click)="deleteNoise(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                    showFirstLastButtons 
                    aria-label="Selectionner la page des marqueurs">
      </mat-paginator>
      
    </div>
    
    <div class="col-md-2">
      <button mat-raised-button (click)="addNoise(noisemodal)">
        <i class="material-icons">add</i> Ajouter
      </button>
    </div>
  </section>
</div>
<div class="loading" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<ng-template #noisemodal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{action}} un document pour le bruit</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
      <div class="form-group full-width-input">
        <label>Point de mesure</label>
        <select class="form-control" formControlName="marker" required>
          <option *ngFor="let marker of markers" value="{{marker.MarkerID}}">{{ marker.Name }}</option>
        </select>
      </div>
      <div class="form-group full-width-input">
        <label>Légende</label>
        <input class="form-control"
              placeholder="optionnel, utilisé que pour les légendes des images"
              type="text"
              formControlName="legend">
      </div>
      <div class="form-group full-width-input">
        <label>Type</label>
        <select class="form-control"
                formControlName="type"
                required>
          <option value="graph">Graphique</option>
          <option value="image">Image</option>
        </select>
      </div>
      <div class="form-group full-width-input" *ngIf="action != 'Modifier'">
        <app-file-upload (fileNameEvent)="fileName($event)"></app-file-upload>
      </div>
      <div class="button-row">
        <button type="button" class="btn btn-primary" (click)="modalCallback()">Ajouter</button>
        <button type="button" class="btn btn-light" (click)="onCancel()">Annuler</button>
      </div>
    </form>
  </div>
</ng-template>