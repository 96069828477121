import { Component, OnInit } from '@angular/core';
import {NgbDate, NgbCalendar, NgbDatepicker, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Chart } from 'chart.js';
import { IavdataService } from '@app/iavdata.service';
import { saveAs } from "file-saver";

@Component({
  selector: 'app-iav',
  templateUrl: './iav.component.html',
  styleUrls: ['./iav.component.css']
})
export class IavComponent implements OnInit {

  iavCanvas;
  iavChart;
  iav2Canvas;
  iav2Chart;
  iav3Canvas;
  iav3Chart;

  dataDay;
  dataWeek;
  dataMonth;

  //Calendar
  model: NgbDateStruct;
  toDate: NgbDate | null = null;

  deviceName = {
    '2F36E5': 'AN20-LF-02',
    '35FA59': 'AN20-LF-03',
    '3663F5': 'AN20-LF-04',
    '3AB651': 'AN20-LF-01',
    '2F3432': 'AN20-MF07'
  };

  constructor(
    private calendar: NgbCalendar,
    private iavdataService: IavdataService,
    public spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.toDate = this.calendar.getToday();
    this.model = this.toDate;
    this.refresh();
    /* setTimeout(() => { */
    /*   this.spinner.hide(); */
    /* }, 2000); */
  }

  public refresh() {
    // Add one day to make it include the toDate
    const date = this.ngbDateToDate(this.calendar.getNext(this.toDate, 'd', 1));
    this.iavdataService.getData(date, 'DAY').subscribe(data => {
        data = this.dataProcessing(data, 15);
        const labels = this.generateDateLabels(data);
        data = this.fillBlanks(data, labels);
        this.dataDay = data;
        this.updateIavChart(data);
    });
    this.iavdataService.getData(date, 'WEEK').subscribe(data => {
        data = this.dataProcessing(data, 15);
        const labels = this.generateDateLabels(data);
        data = this.fillBlanks(data, labels);
        this.dataWeek = data;
        this.updateIav2Chart(data);
    });
    this.iavdataService.getData(date, 'MONTH').subscribe(data => {
        this.spinner.hide();
        data = this.dataProcessing(data, 120);
        const labels = this.generateDateLabels(data);
        data = this.fillBlanks(data, labels);
        this.dataMonth = data;
        this.updateIav3Chart(data);
    });
  }

  // the process define a period_time (in minute) and how many part you want into this period
  dataProcessing(data, period_min_slice=15){
    //Prepare data for chart, for each device
    var device_keys = Object.keys(data);
    for (var pokito = 0; pokito < device_keys.length; pokito++) {
      let key = device_keys[pokito];
      let object_grp = data[key]['snrMeasures'];
      // remove to get first date 15
      if (object_grp.length > 0) {
        let indexBegin = object_grp.findIndex(obj => +('0' + new Date(obj['date']).getMinutes()).slice(-2) % period_min_slice === 0);
        for (var k = 0; k < indexBegin; k++) {
          object_grp.shift();
        }
        let timejump = new Date(object_grp[0]['date']).getTime() + period_min_slice * 60000;
        let x = [];
        let newData = [];
        for (var robywasthere=0; robywasthere<object_grp.length; robywasthere++){
           if(new Date(object_grp[robywasthere]['date']).getTime() < timejump){
             x.push(object_grp[robywasthere]['value']);
           } else {
             if (x.length != 0){
               var zz = 0;
               for (let elemx of x){
                 zz = zz + 10**(elemx/20);
               }
               let nValue = Math.log10((zz/x.length))*20;
               let nDate = new Date(timejump).toISOString();
               newData.push({"date": nDate, "value": nValue});
               x = [];
             }
             while(new Date(object_grp[robywasthere]['date']).getTime() >= timejump){
               let nDate = new Date(timejump).toISOString();
               newData.push({"date": nDate, "value": null});
               timejump = timejump + period_min_slice * 60000
             }
             x.push(object_grp[robywasthere]['value'])
           }
        }
        data[key]['snrMeasures'] = newData;
      }
    }
    return data
    }


  updateIavChart(data) {
    this.iavCanvas = <HTMLCanvasElement> document.getElementById('iavChart');
    if (this.iavCanvas === null) {
      return;
    }
    if (this.iavChart) {
      this.iavChart.destroy();
    }

    //Prepare data for chart, for each device
    var device_keys = Object.keys(data);

    var chartData = {
      labels: this.generateDateLabels(data),
      datasets: [],
    }
    for (var i = 0; i < device_keys.length; i++) {
      let key = device_keys[i];
      let object_grp = data[key]['snrMeasures'];
      let label = this.deviceName[key];

      chartData.datasets.push({
        label: label,
        data: object_grp.map(x => x.value),
        fill: false,
        backgroundColor: this.getColor()[i],
        borderColor: this.getColor()[i],
        cubicInterpolationMode: 'monotone',
      });
    }

    this.iavChart = new Chart(this.iavCanvas.getContext('2d'), {
      type: 'line',
      data: chartData,

      options: {
        responsive: true,
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'IAV Noise Captors'
        },
        animation: {
          animateScale: false,
          animateRotate: false,
        },

        scales: {
          xAxes:[
            {
              id:'xAxis1',
              type:"time",
              time: {
                unit: 'day'
              }
            },
            ],
        },
        spanGaps: true,
      }
    })
  }

  updateIav2Chart(data) {
    this.iav2Canvas = <HTMLCanvasElement> document.getElementById('iav2Chart');
    if (this.iav2Canvas === null) {
      return;
    }
    if (this.iav2Chart) {
      this.iav2Chart.destroy();
    }

    //Prepare data for chart, for each device
    var device_keys = Object.keys(data);

    var chartData = {
      labels: this.generateDateLabels(data),
      datasets: [],
    }
    for (var i = 0; i < device_keys.length; i++) {
      let key = device_keys[i];
      let object_grp = data[key]['snrMeasures'];
      let label = this.deviceName[key];

      chartData.datasets.push({
        label: label,
        data: object_grp.map(x => x.value),
        fill: false,
        backgroundColor: this.getColor()[i],
        borderColor: this.getColor()[i],
        cubicInterpolationMode: 'monotone',
      });
    }

    this.iav2Chart = new Chart(this.iav2Canvas.getContext('2d'), {
      type: 'line',
      data: chartData,

      options: {
        responsive: true,
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'IAV Noise Captors'
        },
        animation: {
          animateScale: false,
          animateRotate: false,
        },

        scales: {
          xAxes:[
            {
              id:'xAxis1',
              type:"time",
              time: {
                unit: 'day'
              }
            },
            ],
        },
        spanGaps: true,
      }
    })
  }

  updateIav3Chart(data) {
    this.iav3Canvas = <HTMLCanvasElement> document.getElementById('iav3Chart');
    if (this.iav3Canvas === null) {
      return;
    }
    if (this.iav3Chart) {
      this.iav3Chart.destroy();
    }

    //Prepare data for chart, for each device
    var device_keys = Object.keys(data);

    var chartData = {
      labels: this.generateDateLabels(data),
      datasets: [],
    }
    for (var i = 0; i < device_keys.length; i++) {
      let key = device_keys[i];
      let object_grp = data[key]['snrMeasures'];
      let label = this.deviceName[key];

      chartData.datasets.push({
        label: label,
        data: object_grp.map(x => x.value),
        fill: false,
        backgroundColor: this.getColor()[i],
        borderColor: this.getColor()[i],
        cubicInterpolationMode: 'monotone',
      });
    }

    this.iav3Chart = new Chart(this.iav3Canvas.getContext('2d'), {
      type: 'line',
      data: chartData,

      options: {
        responsive: true,
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'IAV Noise Captors'
        },
        animation: {
          animateScale: false,
          animateRotate: false,
        },

        scales: {
          xAxes:[
            {
              id:'xAxis1',
              type:"time",
              time: {
                unit: 'day'
              }
            },
            ],
        },
        spanGaps: true,
      }
    })
  }

  onDateSelection(date: NgbDate) {
    this.toDate = date;
    this.dataDay = null;
    this.dataWeek = null;
    this.dataMonth = null;
    this.refresh();
  }

  ngbDateToDate(date: NgbDate) {
    return new Date(date.year, date.month - 1, date.day);
  }

  getColor() {
    return [
      'rgba(255,0,255,0.4)',
      'rgba(0,255,255,0.4)',
      'rgba(255,255,0,0.4)',
      'rgba(0,255,0,0.4)',
      'rgba(0,0,255,0.4)',
      'rgba(255,0,0,0.4)',
    ];
  }

  json2csv(json) {
    const cameras = Object.keys(json);
    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    const header = Object.keys(json[cameras[0]]["snrMeasures"][0]);
    const csv = [
      "cameraId," + header.join(','), // header row first
      ...cameras.map(camera =>
        json[camera]["snrMeasures"].map( row =>
          [camera, ...header.map( fieldName =>
            JSON.stringify(row[fieldName], replacer)
          )].join(',')
        ).join('\r\n')
      )
    ].join('\r\n')
    return csv;
  }

  /* Add missing dates in data */
  fillBlanks(data, labels) {
    if (!labels) {
      console.error('fillBlank got empty labels');
      return data;
    }
    var device_keys = Object.keys(data);

    for (var i = 0; i < device_keys.length; i++) {
      var d = data[device_keys[i]]['snrMeasures'];
      var d2 = [];
      const arr = d.map(item => item['date']);
      labels.forEach(l => {
        const idx = arr.indexOf(l);
        var v = null;
        if ( idx >= 0 ) {
          v = d[idx]['value'];
        }
        const element = {date: l, value: v};
        d2.push(element);
      });
      data[device_keys[i]]['snrMeasures'] = d2;
    }

    return data;
  }

  generateDateLabels(data) {
    var labels = [];
    var set = new Set();

    //Prepare data for chart, for each device
    var device_keys = Object.keys(data);

    for (var i = 0; i < device_keys.length; i++) {
      if (data[device_keys[i]]['snrMeasures']) {
        data[device_keys[i]]['snrMeasures'].forEach(item => set.add(item.date));
      }
    }
    labels = Array.from(set).sort();

    return labels;
  }

  downloadCsvDay() {
    const csv = this.json2csv(this.dataDay);
    const f = new Blob([csv], {type: "text/csv;charset=utf-8"});
    saveAs(f, 'iav-day.csv');

  }

  downloadCsvWeek() {
    const csv = this.json2csv(this.dataWeek);
    const f = new Blob([csv], {type: "text/csv;charset=utf-8"});
    saveAs(f, 'iav-week.csv');
  }

  downloadCsvMonth() {
    const csv = this.json2csv(this.dataMonth);
    const f = new Blob([csv], {type: "text/csv;charset=utf-8"});
    saveAs(f, 'iav-month.csv');
  }
}
