// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverUrl: 'https://friiotnet.tic.heia-fr.ch/api',
  serverPublic: 'https://friiotnet.tic.heia-fr.ch/public',
  loginRedirect: '/admin',
  loginDisplay: '/login',
  oktaBaseUrl: 'https://dev-2101412.okta.com',
  oktaClientId: '0oa2h3ldjc3asNsey5d7',
  colorLowDensity: 'green',
  colorMediumDensity: 'yellow',
  colorHighDensity: '#FF9999',
  colorVeryHighDensity: 'red',
  valueLowDensity: 1000,
  valueMediumDensity: 6000,
  valueHighDensity: 10000
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
