<div class="row text-center">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-2">
            <app-quickselector (dateSelectedRequest)="onDateSelectedRequest($event)"></app-quickselector>
          </div>
          <div class="col-auto">
            <ngb-datepicker
              #dp
              id="custom_calendar"
              [(ngModel)]="model"
              (dateSelect)="onDateSelection($event)"
              [startDate]="fromDate"
              [displayMonths]="1"
              [dayTemplate]="t"
              outsideDays="hidden"
            ></ngb-datepicker>
          </div>
          <div class="col-2">
            <b>{{ 'STAT.START_TIME' | translate }}</b>
            <ngb-timepicker
              [(ngModel)]="fromTime"
              (ngModelChange)="refresh()"
              [seconds]="false"
              [hourStep]="1"
              [minuteStep]="15"
            ></ngb-timepicker>
            <b>{{ 'STAT.END_TIME' | translate }}</b>
            <ngb-timepicker
              [(ngModel)]="toTime"
              (ngModelChange)="refresh()"
              [seconds]="false"
              [hourStep]="1"
              [minuteStep]="15"
            ></ngb-timepicker>
          </div>
        </div>
        <ng-template #t let-date let-focused="focused">
          <span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
          >
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<!---------------------------------------------------------------------- -->

<br>
<nav ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs">
  <ng-container ngbNavItem>
    <a ngbNavLink>{{ 'STAT._ALL_VHC.TAB' | translate }}</a>
    <ng-template ngbNavContent>
        <div class="row text-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <canvas id="VCchart"></canvas>
                        <div style="display: flex; flex-direction: column; justify-content: center;">
                            <br>
                            <p>{{ 'STAT.DIGEST1' | translate }} <b>{{ VCTotVehicle }}</b>
                            (<span [ngStyle]="{'color': setStyleColor(z)}" *ngFor="let tot of VCTotVehiclePerZone; index as z">
                                  {{tot}}
                                </span>)
                            </p>
                            <p>{{ 'STAT.DIGEST2' | translate }} <b>{{ VCAvgVehicle | number: '1.2-2'}}</b>
                            (<span [ngStyle]="{'color': setStyleColor(z)}" *ngFor="let avg of VCAvgVehiclePerZone; index as z">
                                  {{avg | number: '1.2-2'}}
                                </span>)
                            </p>
                        </div>
                        <div class="row">
                          <div class="col text-center">
                            <br>
                            <button class="btn btn-primary" (click)="this.downloadCsvAllVehicles()">
                              {{ 'STAT.DOWNLOAD_CSV' | translate }}
                            </button>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
  </ng-container>

  <ng-container ngbNavItem>
    <a ngbNavLink>{{ 'STAT._2WHEELERS.TAB' | translate }}</a>
    <ng-template ngbNavContent>
        <div class="row text-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body ">
                        <canvas id="VC1chart"></canvas>
                        <div style="display: flex; flex-direction: column; justify-content: center;">
                            <br>
                            <p>{{ 'STAT.DIGEST1' | translate }} <b>{{ VC1TotVehicle }}</b>
                            (<span [ngStyle]="{'color': setStyleColor(z)}" *ngFor="let tot of VC1TotVehiclePerZone; index as z">
                                    {{tot}}
                                </span>)
                            </p>
                            <p>{{ 'STAT.DIGEST2' | translate }} <b>{{ VC1AvgVehicle | number: '1.2-2' }}</b>
                            (<span [ngStyle]="{'color': setStyleColor(z)}" *ngFor="let avg of VC1AvgVehiclePerZone; index as z">
                                    {{avg | number: '1.2-2'}}
                                </span>)
                            </p>
                        </div>
                        <div class="row">
                          <div class="col text-center">
                            <br>
                            <button class="btn btn-primary" (click)="this.downloadCsv2Wheelers()">
                              {{ 'STAT.DOWNLOAD_CSV' | translate }}
                            </button>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink>{{ 'STAT._CARS.TAB' | translate }}</a>
    <ng-template ngbNavContent>
        <div class="row text-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body ">
                        <canvas id="VC2chart"></canvas>
                        <div style="display: flex; flex-direction: column; justify-content: center;">
                            <br>
                            <p>{{ 'STAT.DIGEST1' | translate }} <b>{{ VC2TotVehicle }}</b>
                            (<span [ngStyle]="{'color': setStyleColor(z)}" *ngFor="let tot of VC2TotVehiclePerZone; index as z">
                                    {{tot}}
                                </span>)
                            </p>
                            <p>{{ 'STAT.DIGEST2' | translate }} <b>{{ VC2AvgVehicle | number: '1.2-2' }}</b>
                            (<span [ngStyle]="{'color': setStyleColor(z)}" *ngFor="let avg of VC2AvgVehiclePerZone; index as z">
                                    {{avg | number: '1.2-2'}}
                                </span>)
                            </p>
                        </div>
                        <div class="row">
                          <div class="col text-center">
                            <br>
                            <button class="btn btn-primary" (click)="this.downloadCsvCars()">
                              {{ 'STAT.DOWNLOAD_CSV' | translate }}
                            </button>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
<ng-container ngbNavItem>
    <a ngbNavLink>{{ 'STAT._TRUCKS.TAB' | translate }}</a>
    <ng-template ngbNavContent>
        <div class="row text-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body ">
                        <canvas id="VC3chart"></canvas>
                        <div style="display: flex; flex-direction: column; justify-content: center;">
                            <br>
                            <p>{{ 'STAT.DIGEST1' | translate }} <b>{{ VC3TotVehicle }}</b>
                            (<span [ngStyle]="{'color': setStyleColor(z)}" *ngFor="let tot of VC3TotVehiclePerZone; index as z">
                                    {{tot}}
                                </span>)
                            </p>
                            <p>{{ 'STAT.DIGEST2' | translate }} <b>{{ VC3AvgVehicle | number: '1.2-2' }}</b>
                            (<span [ngStyle]="{'color': setStyleColor(z)}" *ngFor="let avg of VC3AvgVehiclePerZone; index as z">
                                    {{avg | number: '1.2-2'}}
                                </span>)
                            </p>
                        </div>
                        <div class="row">
                          <div class="col text-center">
                            <br>
                            <button class="btn btn-primary" (click)="this.downloadCsvTrucks()">
                              {{ 'STAT.DOWNLOAD_CSV' | translate }}
                            </button>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
</nav>
<div [ngbNavOutlet]="nav" class="mt-2"></div>

<!-- ------------------------------------------------------------------- -->
<div *ngIf="isSpeedAvgData">
  <br>
  <nav ngbNav #nav2="ngbNav" [destroyOnHide]="false" class="nav-tabs">
    <ng-container ngbNavItem>
      <a ngbNavLink>{{ 'STAT.SPEED_AVG' | translate }}</a>
      <ng-template ngbNavContent>
          <div class="row text-center">
              <div class="col-md-12">
                  <div class="card">
                      <div class="card-body">
                          <canvas id="speedChart"></canvas>
                          <div style="display: flex; flex-direction: row; justify-content: center;">
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
      <a ngbNavLink>{{ 'STAT.SPEED85' | translate }}</a>
      <ng-template ngbNavContent>
          <div class="row text-center">
              <div class="col-md-12">
                  <div class="card">
                      <div class="card-body ">
                          <canvas id="speed85Chart"></canvas>
                          <div style="display: flex; flex-direction: row; justify-content: center;">
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav2" class="mt-2"></div>
</div>
