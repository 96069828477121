<footer>
  <div class="d-inline-flex">
    <div class="p-10">
      <p>© 2022 {{ 'FOOTER.CC' | translate }}<br>
      <a [href]="pollutionLink">{{ 'FOOTER.LINK' | translate }}</a></p>
    </div>
    <span class="form-inline p-2 align-items-center center-items-right">
      <select class="form-control" #selectedLang (change)="switchLanguage(selectedLang.value)">
        <option *ngFor="let language of translate.getLangs()" [value]="language"
        [selected]="language === translate.currentLang">
        <span>{{ language.toUpperCase() }}</span>
        </option>
      </select>
    </span>
  </div>
</footer>
