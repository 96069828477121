import { Component, OnInit } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isAuthenticated: boolean;
  isMenuOpen: boolean = false;

  cookieLang;

  constructor(
    public oktaAuth: OktaAuthService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private route: ActivatedRoute
  ) {
    // subscribe to authentication state changes
    this.oktaAuth.$authenticationState.subscribe(
      (isAuthenticated: boolean) => this.isAuthenticated = isAuthenticated
    );

    //default settings for language
    translate.addLangs(['de','en','fr']);

    this.cookieLang = this.cookieService.get('lang');
    this.route.queryParamMap.subscribe((params) => {
      if (params.has('lang')) {
        this.cookieLang = params.get('lang');
        console.log('++');
        this.cookieService.set('lang', this.cookieLang);
      }
      this.cookieLang = this.cookieService.get('lang');
      if (this.cookieLang) {
        console.log('---- cookieLang', this.cookieLang);
        translate.use(this.cookieLang);
      } else {
        this.cookieService.set('lang', 'fr', { path: "/" });
        translate.use('fr');
      }
      moment.locale(this.cookieService.get('lang'));
    });
  }

  async ngOnInit() {
    // get authentication state for immediate use
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();
  }

  async logout() {
    this.isMenuOpen = false;
    await this.oktaAuth.signOut();
  }
}
