<mat-toolbar>
  <mat-icon *ngIf="canNavigateUp" class="pointer" (click)="navigateUp()">
    arrow_back
  </mat-icon>
  <span style="margin-left: 8px"> {{path || 'FILE_EXPLORER' | translate }} </span>
  <span class="spacer"></span>
</mat-toolbar>

<div
  class="container"
  fxFlex
  fxLayout="row"
  fxLayoutAlign="space-between stretch"
>
  <div class="content" fxFlex fxLayout="row">
    <mat-grid-list cols="6" rowHeight="100px" fxFlex>
      <mat-grid-tile
        *ngFor="let element of fileElements"
        class="file-or-folder"
      >
        <div
          fxLayout="column"
          fxLayoutAlign="space-between center"
          (click)="navigate(element)"
        >
          <mat-icon
            color="primary"
            class="file-or-folder-icon pointer"
            *ngIf="element.isFolder"
          >
            folder
          </mat-icon>
          <mat-icon
            color="primary"
            class="file-or-folder-icon pointer"
            *ngIf="!element.isFolder"
          >
            insert_drive_file
          </mat-icon>

          <span>{{element.name}}</span>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
