import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { merge, Observable, of } from 'rxjs';
import { take, map, switchMap, mergeMap, groupBy, reduce, toArray } from 'rxjs/operators';
import dateformat from 'dateformat';

//'https://apeex-server.apeex.org/devices/measures?sigfoxIds=3AB651,2F36E5,35FA59,3663F5,&periodEndTime='+str(epoch_millisec)+'&periodType=MONTH&apiKey=0d7ba6aa-aea3-443b-0618-4269-95df-110a627ded2a'

const IAV_URL: string = 'https://apeex-server.apeex.org';
const API_KEY: string = '0d7ba6aa-aea3-443b-0618-4269-95df-110a627ded2a';
const DEVICES = ['2F36E5', '35FA59', '3663F5', '3AB651', '2F3432'];

@Injectable({
  providedIn: 'root'
})
export class IavdataService {

  constructor(
    private httpService: HttpClient
  ) { }

  getData(period_time_end: Date, period_type='MONTH'): Observable<any> {
    const url_ = IAV_URL + '/measures/devices?sigfoxIds=' + DEVICES.join(',') + '&periodEndTime='+
      period_time_end.getTime() +'&periodType=' + period_type + '&apiKey=' + API_KEY;

    return this.httpService.get(url_);
  }

}
