<div class="container-fluid">
  <div class="row">
    <main class="col">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        Afficher les points de mesures
      </div>

      <div class="mat-elevation-z8 margin-bottom-container">
        <table mat-table [dataSource]="dataSource" matSort>
          <!-- MArker ID Column 
          <ng-container matColumnDef="MarkerID">
            <th mat-header-cell *matHeaderCellDef> Id </th>
            <td mat-cell *matCellDef="let marker"> {{marker.MarkerID}} </td>
          </ng-container> -->
      
          <!-- Name Column -->
          <ng-container matColumnDef="PlaceName">
            <th mat-header-cell *matHeaderCellDef> Nom du lieu </th>
            <td mat-cell *matCellDef="let marker"> {{marker.Name}} </td>
          </ng-container>
      
          <!-- Lat Column -->
          <ng-container matColumnDef="Latitude">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Latitude </th>
            <td mat-cell *matCellDef="let marker"> {{marker.Latitude}} </td>
          </ng-container>

          <!-- Lon Column -->
          <ng-container matColumnDef="Longitude">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Longitude </th>
            <td mat-cell *matCellDef="let marker"> {{marker.Longitude}} </td>
          </ng-container>

          <!-- Type Column -->
          <ng-container matColumnDef="Type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
            <td mat-cell *matCellDef="let marker"> {{marker.Type}} </td>
          </ng-container>

          <!-- Edit Column -->
          <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let marker">
              <button mat-icon-button (click)="editMarker(marker, markermodal)">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Delete Column -->
          <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let marker">
              <button class="mib del" mat-icon-button (click)="deleteMarker(marker)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                      showFirstLastButtons 
                      aria-label="Selectionner la page des marqueurs">
        </mat-paginator>
      </div>
      <div class="col-md-2">
        <button mat-raised-button color="primary" (click)="addMarker(markermodal)">
          <i class="material-icons">add</i> Ajouter
        </button>
      </div>
    </main>
    
    <div class="loading" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>

<ng-template #markermodal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{action}} un point de mesure</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group full-width-input">
        <label>Nom du lieu</label>
        <select class="form-control" markerholder="Nom du marqueur" formControlName="name" required>
          <option *ngFor="let place of places" value="{{place.PlaceID}}">{{place.Name}}</option>
        </select>
      </div>
      <div class="form-group full-width-input">
        <label>Latitude</label>
        <input class="form-control" type="number" formControlName="latitude">
      </div>
      <div class="form-group full-width-input">
        <label>Longitude</label>
        <input class="form-control" type="number" formControlName="longitude">
      </div>
      <div class="form-group full-width-input">
        <label>Type</label>
        <select class="form-control" formControlName="type">
          <option value="pdf">Comptage</option>
          <option value="bruit">Bruit</option>
        </select>
      </div>

      <div class="button-row">
        <button type="button" class="btn btn-primary" (click)="modalCallback()">{{action}}</button>
        <button type="button" class="btn btn-light" (click)="onCancel()">Annuler</button>
      </div>
    </form>
  </div>
</ng-template>
