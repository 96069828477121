import { AfterViewInit, Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BBDataService } from '@app/bbdata.service';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster.layersupport';
import {antPath} from 'leaflet-ant-path';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

import { icon, Marker } from 'leaflet';
import { ServerService } from '@app/service/server.service';
import { FileService } from '@app/service/file.service';
import { FileElement } from '@app/model/fileElement';
import { environment } from 'src/environments/environment';
import { DensityServiceService } from '@app/service/density-service.service';
import { x } from 'ngx-bootstrap-icons';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'asset/marker-shadow.png';

var LeafIcon = L.Icon.extend({
  options: {
    shadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41]
  }
});

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, OnDestroy, AfterViewInit {

  translateSub: Subscription;
  mapBaseMapsColoredLabel!: string;
  mapBaseMapsGrayLabel!: string;
  mapOverlayMapsNoiseLabel!: string;
  mapOverlayMapsLiveTraficLabel!: string;
  mapOverlayMapsHistoricTraficLabel!: string;
  mapOverlayMapsDailyTraficLabel!: string;
  mapOverlayMapsLowLabel!: string;
  mapOverlayMapsMediumLabel!: string;
  mapOverlayMapsHighLabel!: string;
  mapOverlayMapsVeryHighLabel!: string;

  mapControl;

  locId;                  //id of a locality.
  locName: string;                //locality name.
  location_info: any;             //camera_config param data.
  marker_info: any;               //marker info from the mysql database
  markersInfo: any;               //marker info from the mysql database
  isStatShow: BehaviorSubject<boolean> =
    new BehaviorSubject(false);    //boolean to show/hidde the stat component.
  isPdfShow: boolean = false;     //boolean to show/hidde the pdf-viewer component.
  isNoiseShow: boolean = false;   //boolean to show/hidde the noise component.
  isMapInfoShow: boolean = false; //boolean to show/hidde the drawer

  //About the file browser
  docs: any[] = [];
  years: any[] = [];              //different years of the documents
  pdfs: any[] = [];               //info about the documents (historic data)
  currentRoot: FileElement;
  currentPath: string;
  canNavigateUp = false;

  pdfSrc: string;
  markerId: number;
  modalRef: BsModalRef;
  fileElements: Observable<FileElement[]>;

  //Use to config the map with mapBox
  mapBoxToken = 'pk.eyJ1Ijoia3Jha3QiLCJhIjoiY2t3MmQwemYxMDA5MDMwcGFua21rc3N5bSJ9.XOTiHUsqnlQfnx5Qo8p45A';
  mapBoxUrl = 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}';
  mapBoxAttribution = 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>';

  roadSection: any;
  pathLayer = L.geoJSON(null, { //layer with the sections to display on the map
    style: function(feature) {
      if(feature.properties.density <= environment.valueLowDensity)
        return {color: environment.colorLowDensity};
      else if (feature.properties.density > environment.valueLowDensity && feature.properties.density <= environment.valueMediumDensity)
        return {color: environment.colorMediumDensity};
      else if (feature.properties.density > environment.valueMediumDensity && feature.properties.density <= environment.valueHighDensity)
        return {color: environment.colorHighDensity};
      else
        return {color: environment.colorVeryHighDensity};
    }
  });
  private map: any;
  private clu: any;
  private pdfLayer: any;
  private noiseLayer: any;
  private statLayer: any;

  private overlayState: Boolean[];

  CustomMarker = L.Marker.extend({
    options: {
      location_id: -1,
      iconUrl: '',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png'
    }
  });

  grayscale = L.tileLayer(this.mapBoxUrl,
    {
      id: 'mapbox/light-v10',
      attrribution: this.mapBoxAttribution,
      accessToken: this.mapBoxToken
    });

  normal = L.tileLayer(this.mapBoxUrl,
    {
      id: 'mapbox/streets-v11',
      attrribution: this.mapBoxAttribution,
      accessToken: this.mapBoxToken
    });

  @ViewChild('iframe') iframe: ElementRef;

  constructor(
    private bbdataService: BBDataService,
    private server: ServerService,
    private densityService: DensityServiceService,
    public fileService: FileService,
    private modalService: BsModalService,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {
    this.densityService.getStateShapes().subscribe(densities => {
      this.roadSection = densities;
      this.initRoadSection();
    });
  }

  modalCallback: () => void;

  ngOnInit(): void {
    this.location_info = this.bbdataService.getAvailableLocations();  //get camera config file
    this.isStatShow.subscribe(state => {
      if (state) {
      } else {
        this.currentRoot = null;
        this.currentPath = '';
        this.canNavigateUp = false;
      }
    });
    this.overlayState = [];
  }

  ngAfterViewInit() {
    this.initMap();
    this.translateSub = this.translate.onLangChange.subscribe((event) => {
      console.log("triggered");
      console.log(event);
      this.overlayState = this.saveOverlayState();
      if (this.pdfLayer && this.noiseLayer && this.statLayer)
        this.clu?.checkOut([this.pdfLayer, this.noiseLayer, this.statLayer]);
      this.getMarkers().then((markers) => this.renderMap(markers));
    });
  }

  saveOverlayState(): Boolean[] {
    let elements = document.getElementsByClassName("leaflet-control-layers-selector");
    if (elements.length > 2) { // 2 are the numbers of map styles TODO unclean
      let r = [];
      for (let i=2; i<elements.length; i++) {
        let e = elements.item(i) as HTMLInputElement;
        r.push(e.checked);
      }
      return r;
    }
    return [];
  }

  ngOnDestroy() {
    this.mapControl?.remove(this.map);
    this.translateSub.unsubscribe();
  }

  /**
   * Create the geoJson path and color on the map
   */
  async initRoadSection() {

    await this.server.getDensities().then((test) => {
      for(let a of this.roadSection.features) {
        const b = test.find(x => x.RoadSectionID == a.properties.section);
        a.properties.density = b.Density;
      }
    });

    this.pathLayer.addData(this.roadSection);
  }

  /**
   * Init the map
   */
  private initMap(): void {
    this.map = L.map('terkep', {
    }).setView([46.7, 7.1], 10);

    const tiles = L.tileLayer(this.mapBoxUrl, {
      attribution: this.mapBoxAttribution,
      id: 'mapbox/streets-v11',
      accessToken: this.mapBoxToken
    });

    this.normal.addTo(this.map);
  }

  async getMarkers() {
    return await this.server.getMarkers();
  }

  createMarkerForLiveCam() {
    let c = [];
    let purpuleIcon = new LeafIcon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-violet.png'
    })

    //Create marker from the camera_config file
    for (let location of this.location_info) {
      let m = new this.CustomMarker([location.coordinates.latitude, location.coordinates.longitude], {
        location_id: location.object_group_id,
        locationName: location.object_group_name,
        locationCity: location.city
      });

      m.bindPopup(location.object_group_name);
      m.on('mouseover', e => m.openPopup()),
      m.on('mouseout', e => m.closePopup()),
      m.on('click', e => this.onLocationSelection(e));
      m.setIcon(purpuleIcon);
      c.push(m)
    }

    return c;
  }

  createMarkerFromDataBase() {
    let p = [];
    let n = [];
    let greenIcon = new LeafIcon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png'
    })

    let redIcon = new LeafIcon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png'
    })

    for (let mark of this.markersInfo) {
      let m = new this.CustomMarker([mark.Latitude, mark.Longitude], {
        locationName: mark.Name,
        markerID: mark.MarkerID,
        type: mark.Type
      });

      m.bindPopup(mark.Name);
      m.on('mouseover', e => m.openPopup()),
      m.on('mouseout', e => m.closePopup()),
      m.on('click', e => this.onLocationSelection(e));


      if(mark.Type == "pdf") {
        m.setIcon(redIcon);
        p.push(m);
      }else if (mark.Type == "bruit") {
        m.setIcon(greenIcon);
        n.push(m);
      }
    }
    return new Array(p, n);
  }

  renderMap(markers: any) {
    this.markersInfo = markers;
    let a = this.createMarkerFromDataBase();
    let b = this.createMarkerForLiveCam();

    this.clu = L.markerClusterGroup.layerSupport();
    this.clu.addTo(this.map);

    this.pdfLayer = L.layerGroup();//.addTo(clu);
    this.noiseLayer = L.layerGroup();//.addTo(clu);
    this.statLayer = L.layerGroup();//.addTo(clu);
    this.clu.checkIn([this.pdfLayer, this.noiseLayer, this.statLayer]);

    // select or not the layers
    if (this.overlayState.length > 0) {
      if (this.overlayState.length >=4) {
        if (this.overlayState[0])
          this.noiseLayer.addTo(this.map);
        if (this.overlayState[1])
          this.statLayer.addTo(this.map);
        if (this.overlayState[2])
          this.pdfLayer.addTo(this.map);
        if (this.overlayState[3])
          this.pathLayer.addTo(this.map);
      }
    } else { // Default is to show all layers
      this.noiseLayer.addTo(this.map);
      this.statLayer.addTo(this.map);
      this.pdfLayer.addTo(this.map);
      this.pathLayer.addTo(this.map);
    }

    for (var i = 0; i < a[0].length; ++i) {
      var mF = a[0][i];
      mF.addTo(this.pdfLayer);
    }

    for (var i = 0; i < a[1].length; ++i) {
      var mF = a[1][i];
      mF.addTo(this.noiseLayer);
    }

    for (var i = 0; i < b.length; ++i) {
      var mF = b[i];
      mF.addTo(this.statLayer);
    }

    let baseMaps = {};
    let normal_ = this.normal;
    let grayscale_ = this.grayscale;
    this.translate.get([
      'MAP.BASE_MAPS.COLORED',
      'MAP.BASE_MAPS.GRAY'
    ]).subscribe(transl => {
      this.mapBaseMapsColoredLabel = transl['MAP.BASE_MAPS.COLORED'];
      this.mapBaseMapsGrayLabel = "<span style='color: gray'>" +
        transl['MAP.BASE_MAPS.GRAY'] + "</span>";
      baseMaps[this.mapBaseMapsColoredLabel] = normal_;
      baseMaps[this.mapBaseMapsGrayLabel] = grayscale_;
    });
    let overlayMaps = {};
    let pathLayer = this.pathLayer;
    this.translate.get([
      'MAP.OVERLAY_MAPS.NOISE',
      'MAP.OVERLAY_MAPS.LIVE_TRAFIC',
      'MAP.OVERLAY_MAPS.HISTORIC_TRAFIC',
      'MAP.OVERLAY_MAPS.DAILY_TRAFIC',
      'MAP.OVERLAY_MAPS.LOW',
      'MAP.OVERLAY_MAPS.MEDIUM',
      'MAP.OVERLAY_MAPS.HIGH',
      'MAP.OVERLAY_MAPS.VERY_HIGH',
      'MAP.OVERLAY_MAPS.AND',
      'MAP.OVERLAY_MAPS.TJM'
    ]).subscribe(transl => {
      this.mapOverlayMapsNoiseLabel = "<span style='color: green'>" +
        transl['MAP.OVERLAY_MAPS.NOISE'] + "</span>";
      this.mapOverlayMapsLiveTraficLabel = "<span style='color: purple'>" +
        transl['MAP.OVERLAY_MAPS.LIVE_TRAFIC'] + "</span>";
      this.mapOverlayMapsHistoricTraficLabel = "<span style='color: red'>" +
        transl['MAP.OVERLAY_MAPS.HISTORIC_TRAFIC'] + "</span>";
      this.mapOverlayMapsDailyTraficLabel = "" +
        transl['MAP.OVERLAY_MAPS.DAILY_TRAFIC'] + "<ul>" +
        "<li><span style='color:green'>" +
        transl['MAP.OVERLAY_MAPS.LOW'] +
        "<br>"+transl['MAP.OVERLAY_MAPS.TJM']+" <= 1'000</span></li>" +
        "<li><span style='color:#E1E132'>" +
        transl['MAP.OVERLAY_MAPS.MEDIUM'] +
        "<br>"+transl['MAP.OVERLAY_MAPS.TJM']+" > 1'000 < "+transl['MAP.OVERLAY_MAPS.AND']+" <= 6'000</span></li>" +
        "<li><span style='color:#FF9999'>" +
        transl['MAP.OVERLAY_MAPS.HIGH'] +
        "<br>"+transl['MAP.OVERLAY_MAPS.TJM']+" > 6'000 "+transl['MAP.OVERLAY_MAPS.AND']+" <= 10'000</span></li>" +
        "<li><span style='color:red'>" +
        transl['MAP.OVERLAY_MAPS.VERY_HIGH'] +
        "<br>"+transl['MAP.OVERLAY_MAPS.TJM']+" > 10'000</span></li></ul>";

      overlayMaps[this.mapOverlayMapsNoiseLabel] = this.noiseLayer;
      overlayMaps[this.mapOverlayMapsLiveTraficLabel] = this.statLayer;
      overlayMaps[this.mapOverlayMapsHistoricTraficLabel] = this.pdfLayer;
      overlayMaps[this.mapOverlayMapsDailyTraficLabel] = this.pathLayer;

    });

    let o = {
      "collapsed": false
    };
    this.mapControl?.remove(this.map);
    this.mapControl = L.control.layers(baseMaps, overlayMaps, o).addTo(this.map);
  }

  async createFileBrowser(markerId: number) {
    this.fileService.reset();

    this.docs = await this.server.getDocumentsByMarkerID(markerId);
    this.years = await this.server.getDocumentsByYears(markerId);

    for(let doc of this.docs) {
      const folder = this.fileService.add({ name: doc.Name, isFolder: true, parent: 'root' });
      for(let year of this.years) {
        const folder1 = this.fileService.add({ name: year.YearOfMeasure, isFolder: true, parent: folder.id });
        this.pdfs = await this.server.getDocumentsByMarkers(markerId, year.YearOfMeasure);
        for(let pdf of this.pdfs) {
          this.fileService.add({ name: pdf.FileName, isFolder: false, parent: folder1.id, path: pdf.PathToFile });
        }
      }
    }
    this.updateFileElementQuery();
  }

  async onLocationSelection(e) {
    const opt = e.sourceTarget.options;
    this.isMapInfoShow = true;
    this.isStatShow.next(false);
    this.isNoiseShow = false;
    this.isPdfShow = false;

    if (opt.type == "pdf") {
      this.createFileBrowser(opt.markerID);
      this.locName = opt.locationName;
      this.isPdfShow = true;
    } else if (opt.type == "bruit") {
      this.locName = opt.locationName;
      this.markerId = opt.markerID;
      this.isNoiseShow = true;
    } else {
      this.locName = opt.locationCity + ": " + opt.locationName;
      this.locId = opt.location_id;
      this.isStatShow.next(true);
    }
  }

  updateFileElementQuery() {
    this.fileElements = this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.id : 'root');
  }

  navigateToFolder(element: FileElement) {
    this.currentRoot = element;
    this.updateFileElementQuery();
    this.currentPath = this.pushToPath(this.currentPath, element.name);
    this.canNavigateUp = true;
  }

  openFile(element: FileElement, template: any) {
    this.currentRoot = element;
    console.log("path", environment.serverPublic + '/' + this.currentRoot.path);
    this.pdfSrc = environment.serverPublic + '/' + this.currentRoot.path;
    this.modalRef = this.modalService.show(template);
  }

  pushToPath(path: string, folderName: string) {
    let p = path ? path : '';
    p += `${folderName}/`;
    return p;
  }

  navigateUp() {
    if (this.currentRoot && this.currentRoot.parent === 'root') {
      this.currentRoot = null;
      this.canNavigateUp = false;
      this.updateFileElementQuery();
    } else {
      this.currentRoot = this.fileService.get(this.currentRoot.parent);
      this.updateFileElementQuery();
    }
    this.currentPath = this.popFromPath(this.currentPath);
  }

  popFromPath(path: string) {
    let p = path ? path : '';
    let split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }

  onClose(): void {
    this.isStatShow.next(false);
    this.isPdfShow = false;
    this.isNoiseShow = false;
    this.isMapInfoShow = false;
    this.locName = '';
  }

}
