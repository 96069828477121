import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-quickselector',
  templateUrl: './quickselector.component.html',
  styleUrls: ['./quickselector.component.css']
})
export class QuickselectorComponent implements OnInit {

  @Output() dateSelectedRequest: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  submitEvent(value : string) {
    this.dateSelectedRequest.emit(value);
  }

}
