<div class="container-fluid">
  <div class="row">
  <main class="col">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      Admin Home
    </div>

    <div class="card">
      <div class="card-header">
        <span class="material-icons">
          help
        </span>
        Mode d'emploi
      </div>
      <div class="card-body">
        <h5 class="card-title">Rajouter des informations sur la carte</h5>
        <p class="card-text">
          <ol>
            <li>
              Inserer un lieu grâce au menu <span class="material-icons">location_searching</span> "Lieux" qui se trouve dans le menu de gauche.
            </li>
            <li>
              Inserer un point de mesure grâce au menu <span class="material-icons">place</span> "Points de mesure" qui se trouve dans le menu de gauche.
              <ul>
                <li>
                  Le type "Comptage" permet de créer un point de mesure pour plus tard pouvoir rajouter les documents de mesure <span class="material-icons">picture_as_pdf</span>.
                </li>
                <li>
                  Le type "Bruit" permet de créer un point de mesure pour plus tard pouvoir rajouter les documents de mesure <span class="material-icons">surround_sound</span>.
                </li>
              </ul>
            </li>
            <li>
              Inserer des documents (<span class="material-icons">picture_as_pdf</span> ou <span class="material-icons">surround_sound</span>) en rapport avec le point de mesure ajouté au point 2 de cette liste.
              <ul>
                <li>
                  Lors de l'ajout de document pour les bruits (<span class="material-icons">surround_sound</span>) les types correspondent à:
		  <ul>
		    <li> Graphique: l'image du bas avec le graphique du bruit</li>
		    <li> Image: l'image du carousel. Vous pouvez en ajouter plusieurs. La légende n'est pas obligatoire mais sera affichée sur l'image si vous en écrivez une.</li>
		  </ul>
                </li>
              </ul>
            </li>
	    <li>
	     Inserer des densités grâce au menu (<span class="material-icons">traffic</span>) "Densité" qui se trouve dans le menu de gauche. 
	    </li>
          </ol>
        <a routerLink="/places" class="btn btn-primary">Aller créer un lieu</a>
      </div>
    </div>
  </main>
</div>
