import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-decent-lab',
  templateUrl: './decent-lab.component.html',
  styleUrls: ['./decent-lab.component.css']
})
export class DecentLabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
