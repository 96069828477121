<!-- <br> -->
<div class="col-md-12 text-center">
  <ngb-datepicker #dpi
     id="custom_calendar"  
     [(ngModel)]="model"
     (dateSelect)="onDateSelection($event);spinner.show()"
     [displayMonths]="1"
     outsideDays="hidden"
     ></ngb-datepicker>
</div>
<!-- <br> -->
<nav ngbNav #iavnav="ngbNav" [destroyOnHide]="false" class="nav-tabs">
  <ng-container ngbNavItem>
    <ngx-spinner bdColor = "rgba(0,0,0,0.8)" size = "default" color = "#FFFFFF" type = "ball-clip-rotate" [fullScreen] = "true">
      <p style="color : #FFFFFF"> Loading... </p>
    </ngx-spinner>
    <a ngbNavLink>Day</a>
    <ng-template ngbNavContent>
        <div class="row text-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body chart-container">
                        <canvas id="iavChart"></canvas>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <br>
                <button class="btn btn-primary" (click)="this.downloadCsvDay()">Download CSV</button>
            </div>
        </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink>Week</a>
    <ng-template ngbNavContent>
        <div class="row text-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body chart-container">
                        <canvas id="iav2Chart"></canvas>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <br>
                <button class="btn btn-primary" (click)="this.downloadCsvWeek()">Download CSV</button>
            </div>
        </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink>Month</a>
    <ng-template ngbNavContent>
        <div class="row text-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body chart-container">
                        <canvas id="iav3Chart"></canvas>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <br>
                <button class="btn btn-primary" (click)="this.downloadCsvMonth()">Download CSV</button>
            </div>
        </div>
    </ng-template>
  </ng-container>
</nav>
<div [ngbNavOutlet]="iavnav" class="mt-2"></div>
