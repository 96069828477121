import { Component, Input, OnChanges } from '@angular/core';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbCalendar, NgbDatepicker, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Chart } from 'chart.js';
import { BBDataService } from '@app/bbdata.service';
import { ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dateformat from 'dateformat';
import { saveAs } from "file-saver";
import _ from 'lodash';

@Component({
  selector: 'app-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.css']
})
export class StatComponent implements OnChanges {
  _allVhcLabel!: string;
  _2wheelersLabel!: string;
  _carsLabel!: string;
  _trucksLabel!: string;
  speedAvgLabel!: string;
  speed85Label!: string;
  todayLabel!: string;
  yesterdayLabel!: string;
  thisWeekLabel!: string;
  lastWeekLabel!: string;
  thisMonthLabel!: string;
  lastMonthLabel!: string;
  thisYearLabel!: string;
  lastYearLabel!: string;
  allLabel!: string;
  isSpeedAvgData: boolean;
  isSpeed85Data: boolean;

  color_scheme = [
    'red',
    'blue',
    'green',
    'fuchsia',
    'cyan',
  ];

  setStyleColor(id: number): string {
    return `${this.color_scheme[id]};`;
  }


  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  @Input() el_input: number;

  @ViewChild('dp') datepicker: ElementRef;
  date_origin : string;

  //Calendar
  model: NgbDateStruct;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  boolStepSelection: boolean = false; // for date picker handling

  //Second are required, but are not modifiable from the user interface
  fromTime: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
  toTime: NgbTimeStruct = { hour: 23, minute: 45, second: 59 };

  //Data
  dataAll;
  dataTrucks;
  dataCars;
  data2Wheelers;

  location_info;

  // Charts
  VCcanvas;
  VCchart;
  VC1canvas;
  VC1chart;
  VC2canvas;
  VC2chart;
  VC3canvas;
  VC3chart;
  speedCanvas;
  speedChart;
  speed85Canvas;
  speed85Chart;

  borderWidth1 = 0.6;


  VCTotVehicle;
  VCTotVehiclePerZone;
  VCAvgVehicle;
  VCAvgVehiclePerZone;

  VC1TotVehicle;
  VC1TotVehiclePerZone;
  VC1AvgVehicle;
  VC1AvgVehiclePerZone;

  VC2TotVehicle;
  VC2AvgVehicle;
  VC2TotVehiclePerZone;
  VC2AvgVehiclePerZone;

  VC3TotVehicle;
  VC3AvgVehicle;
  VC3TotVehiclePerZone;
  VC3AvgVehiclePerZone;

  reduceDataPointsPlugin = {
    beforeUpdate: function (chart, options) {
      this.filterData(chart);
    }
  };

  xAxes = [
    {
      id: 'xAxis1',
      type: "category",
      ticks: {
        callback: function (label) {
          if (!label) {
            return null;
          }
          var _date = label.split(" ")[0];
          var _hour = label.split(" ")[1];
          return _hour;
        }
      }
    },
    {
      id: 'xAxis2',
      type: "category",
      gridLines: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
      autoSkip: false,
      ticks: {
        callback: (label, index: number, labels) => {
          if (!label) {
            return null;
          }
          var _dates = labels.map(x => x.split(" ")[0]);
          var _date = label.split(" ")[0];

          var first_occurence: number = _dates.indexOf(_date);
          //var last_occurence: number = _dates.lastIndexOf(_date);
          //var middle: number = ((last_occurence + first_occurence) / 2) | 0;

          if (index == first_occurence) {
            return _date;
          }
          return null;
        },
        autoSkip: false
      },

    }];

  constructor(
    private calendar: NgbCalendar,
    private activatedRoute: ActivatedRoute,
    private bbdataService: BBDataService,
    private translate: TranslateService
  ) {
    this.getLabels();
    this.isSpeedAvgData = true;
    this.isSpeed85Data = true;
  }

  ngOnChanges() {
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', this.calendar.getWeekday(this.calendar.getToday()) - 1);
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 7 - this.calendar.getWeekday(this.calendar.getToday()));
    this.date_origin = 'THIS_WEEK';

    this.location_info = this.getLocationInfoFromGroupId(this.el_input);
    this.refresh(); // update the graph on first load
  }

  getLabels() {
    this.translate.get([
      'STAT.SPEED85',
      'STAT.SPEED_AVG',
      'STAT._ALL_VHC.TITLE',
      'STAT._2WHEELERS.TITLE',
      'STAT._CARS.TITLE',
      'STAT._TRUCKS.TITLE',
      'STAT.TODAY',
      'STAT.YESTERDAY',
      'STAT.THIS_WEEK',
      'STAT.LAST_WEEK',
      'STAT.THIS_MONTH',
      'STAT.LAST_MONTH',
      'STAT.THIS_YEAR',
      'STAT.LAST_YEAR',
      'STAT.ALL'
    ]).subscribe(transl => {
      this.speed85Label = transl['STAT.SPEED85'];
      this.speedAvgLabel = transl['STAT.SPEED_AVG'];
      this._allVhcLabel = transl['STAT._ALL_VHC.TITLE'];
      this._2wheelersLabel = transl['STAT._2WHEELERS.TITLE'];
      this._carsLabel = transl['STAT._CARS.TITLE'];
      this._trucksLabel = transl['STAT._TRUCKS.TITLE'];
      this.todayLabel = transl['STAT.TODAY'];
      this.yesterdayLabel = transl['STAT.YESTERDAY'];
      this.todayLabel = transl['STAT.TODAY'];
      this.thisWeekLabel = transl['STAT.THIS_WEEK'];
      this.lastWeekLabel = transl['STAT.LAST_WEEK'];
      this.thisMonthLabel = transl['STAT.THIS_MONTH'];
      this.lastMonthLabel = transl['STAT.LAST_MONTH'];
      this.thisYearLabel = transl['STAT.THIS_YEAR'];
      this.lastYearLabel = transl['STAT.LAST_YEAR'];
      this.allLabel = transl['STAT.ALL'];
    });
  }

  // getLabels() {
  //   this.translate.get([
  //     'STAT.SPEED85',
  //     'STAT.SPEED_AVG',
  //     'STAT._ALL_VHC.TITLE',
  //     'STAT._2WHEELERS.TITLE',
  //     'STAT._CARS.TITLE',
  //     'STAT._TRUCKS.TITLE',
  //   ]).subscribe(transl => {
  //     this.speed85Label = transl['STAT.SPEED85'];
  //     this.speedAvgLabel = transl['STAT.SPEED_AVG'];
  //     this._allVhcLabel = transl['STAT._ALL_VHC.TITLE'];
  //     this._2wheelersLabel = transl['STAT._2WHEELERS.TITLE'];
  //     this._carsLabel = transl['STAT._CARS.TITLE'];
  //     this._trucksLabel = transl['STAT._TRUCKS.TITLE'];
  //   });
  // }

  // initDurationDict() {
  //   this.translate.get([
  //     'STAT.SPEED85',
  //     'STAT.SPEED_AVG',
  //     'STAT._ALL_VHC.TITLE',
  //     'STAT._2WHEELERS.TITLE',
  //     'STAT._CARS.TITLE',
  //     'STAT._TRUCKS.TITLE',
  //   ]).subscribe(transl => {
  //     this.speed85Label = transl['STAT.SPEED85'];
  //     this.speedAvgLabel = transl['STAT.SPEED_AVG'];
  //     this._allVhcLabel = transl['STAT._ALL_VHC.TITLE'];
  //     this._2wheelersLabel = transl['STAT._2WHEELERS.TITLE'];
  //     this._carsLabel = transl['STAT._CARS.TITLE'];
  //     this._trucksLabel = transl['STAT._TRUCKS.TITLE'];
  //   });
  // }
  //     'TODAY',
  //     'YESTERDAY',
  //     'THIS_WEEK',
  //     'LAST_WEEK',
  //     'THIS_MONTH',
  //     'LAST_MONTH',
  //     'THIS_YEAR',
  //     'LAST_YEAR',
  //     'ALL',
  //     this.todayLabel = transl['STAT.TODAY'];
  //     this.yesterdayLabel = transl['STAT.YESTERDAY'];
  //     this.todayLabel = transl['STAT.TODAY'];
  //     this.thisWeekLabel = transl['STAT.THIS_WEEK'];
  //     this.lastWeekLabel = transl['STAT.LAST_WEEK'];
  //     this.thisMonthLabel = transl['STAT.THIS_MONTH'];
  //     this.lastMonthLabel = transl['STAT.LAST_MONTH'];
  //     this.thisYearLabel = transl['STAT.THIS_YEAR'];
  //     this.lastYearLabel = transl['STAT.LAST_YEAR'];
  //     this.allLabel = transl['STAT.ALL'];

  getDayLabel(date_origin: string): string {
    switch (date_origin) {
      case 'TODAY':
        return this.todayLabel;
      case 'YESTERDAY':
        return this.yesterdayLabel;
      case 'THIS_WEEK':
        return this.thisWeekLabel;
      case 'LAST_WEEK':
        return this.lastWeekLabel;
      case 'THIS_MONTH':
        return this.thisMonthLabel;
      case 'LAST_MONTH':
        return this.lastMonthLabel;
      case 'THIS_YEAR':
        return this.thisYearLabel;
      case 'LAST_YEAR':
        return this.lastYearLabel;
      case 'ALL':
        return this.allLabel;
      default:
        return '';
    }
  }

  // Apply corresponding function if date_origin is from a button (and not from fixed date)
  // It ensures that 'next month' is indeed next month, and not a fixed value from when the filter was created.
  apply_date_by_origin(date_origin) {
    switch (date_origin) {
      case 'TODAY':
        this.selectRapidToday();
        break;
      case 'YESTERDAY':
        this.selectRapidYesterday();
        break;
      case 'THIS_WEEK':
        this.selectRapidThisWeek();
        break;
      case 'LAST_WEEK':
        this.selectRapidLastWeek();
        break;
      case 'THIS_MONTH':
        this.selectRapidThisMonth();
        break;
      case 'LAST_MONTH':
        this.selectRapidLastMonth();
        break;
      case 'THIS_YEAR':
        this.selectRapidThisYear();
        break;
      case 'LAST_YEAR':
        this.selectRapidLastYear();
        break;
      case 'ALL':
        this.selectRapidAllTime();
        break;
      default:
        // must be from date picker
        break;
    }
  }

  selectRapidToday() {
    this.fromDate = this.calendar.getToday();
    this.toDate = this.fromDate;
    this.date_origin = 'TODAY';
    this.refresh();
  }

  selectRapidYesterday() {
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 1);
    this.toDate = this.fromDate;
    this.date_origin = 'YESTERDAY';
    this.refresh();
  }

  selectRapidThisWeek() {
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', this.calendar.getWeekday(this.calendar.getToday()) - 1);
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 7 - this.calendar.getWeekday(this.calendar.getToday()));
    this.date_origin = 'THIS_WEEK';
    this.refresh();
  }

  selectRapidLastWeek() {
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', this.calendar.getWeekday(this.calendar.getToday()) + 6);
    this.toDate = this.calendar.getPrev(this.calendar.getToday(), 'd', this.calendar.getWeekday(this.calendar.getToday()));
    this.date_origin = 'LAST_WEEK';
    this.refresh();
  }

  selectRapidThisMonth() {
    this.fromDate = new NgbDate(this.calendar.getToday().year, this.calendar.getToday().month, 1);
    this.toDate = this.calendar.getPrev(this.calendar.getNext(this.fromDate, 'm', 1), 'd', 1);
    this.date_origin = 'THIS_MONTH';
    this.refresh();
  }

  selectRapidLastMonth() {
    if (this.calendar.getToday().month == 1) {
      this.fromDate = new NgbDate(this.calendar.getToday().year - 1, this.calendar.getToday().month + 11, 1);
    } else {
      this.fromDate = new NgbDate(this.calendar.getToday().year, this.calendar.getToday().month - 1, 1);
    }
    this.toDate = this.calendar.getPrev(this.calendar.getNext(this.fromDate, 'm', 1), 'd', 1);
    this.date_origin = 'LAST_MONTH';
    this.refresh();
  }

  selectRapidThisYear() {
    this.fromDate = new NgbDate(this.calendar.getToday().year, 1, 1);
    this.toDate = this.calendar.getPrev(this.calendar.getNext(this.fromDate, 'y', 1), 'd', 1);
    this.date_origin = 'THIS_YEAR';
    this.refresh();
  }

  selectRapidLastYear() {
    this.fromDate = new NgbDate(this.calendar.getToday().year - 1, 1, 1);
    this.toDate = this.calendar.getPrev(this.calendar.getNext(this.fromDate, 'y', 1), 'd', 1);
    this.date_origin = 'LAST_YEAR';
    this.refresh();
  }

  selectRapidAllTime() {
    this.fromDate = new NgbDate(2014, 1, 1); //TODO: dynamically pick the first stamp date ?
    this.toDate = this.calendar.getPrev(this.calendar.getToday());
    this.date_origin = 'ALL';
    this.refresh();
  }

  onDateSelection(date: NgbDate) {
    if (this.boolStepSelection) {
      if (date.equals(this.fromDate)) {
        this.toDate = this.fromDate;
        this.date_origin = 'DP';
        this.refresh();
      } else if (date.before(this.fromDate)) {
        this.fromDate = date;
      } else { // date after
        this.toDate = date;
        this.date_origin = 'DP';
        this.refresh();
      }
    } else {
      this.fromDate = date;
      this.toDate = null;
      this.boolStepSelection = true;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  public refresh() {
    this.boolStepSelection = false;
    var from_date_string = this.format_date_to_bbdata_format(this.fromDate, this.fromTime);
    var to_date_string = this.format_date_to_bbdata_format(this.toDate, this.toTime, true);

    let zone_indexes = [...Array(this.location_info["zones"].length).keys()]
    Promise.all(
      zone_indexes.map(zone_index => this.bbdataService.getTotalVehicles(this.location_info, zone_index, from_date_string, to_date_string))
    ).then(
      (data) => {
        data.map(e => e.pop());
        data = this.fillDataWithZeros(data);
        this.dataAll = data;
        this.updateVCChart(data, this.location_info);
      },
      (err) => console.error(err)
    );
    Promise.all(
      zone_indexes.map(zone_index => this.bbdataService.getVCx("VC1", this.location_info, zone_index, from_date_string, to_date_string))
    ).then(
      (data) => {
        data.map(e => e.pop());
        data = this.fillDataWithZeros(data);
        this.dataTrucks = data;
        this.updateVC1Chart(data, this.location_info);
      },
      (err) => console.error(err)
    );
    Promise.all(
      zone_indexes.map(zone_index => this.bbdataService.getVCx("VC2", this.location_info, zone_index, from_date_string, to_date_string))
    ).then(
      (data) => {
        data.map(e => e.pop());
        data = this.fillDataWithZeros(data);
        this.dataCars = data;
        this.updateVC2Chart(data, this.location_info);
      },
      (err) => console.error(err)
    );
    Promise.all(
      zone_indexes.map(zone_index => this.bbdataService.getVCx("VC3", this.location_info, zone_index, from_date_string, to_date_string))
    ).then(
      (data) => {
        data.map(e => e.pop());
        data = this.fillDataWithZeros(data);
        this.data2Wheelers = data;
        this.updateVC3Chart(data, this.location_info);
      },
      (err) => console.error(err)
    );


    Promise.all(
      zone_indexes.map(zone_index => this.bbdataService.getAvgSpeed(this.location_info, zone_index, from_date_string, to_date_string))
    ).then(
      (data) => {
        data.map(e => e.pop());
        data = !data[0] ? this.fillDataWithZeros(data) : data;
        this.updateSpeedChart(data, this.location_info);
      },
      (err) => console.error(err)
    );

    Promise.all(
      zone_indexes.map(zone_index => this.bbdataService.getSpeed85(this.location_info, zone_index, from_date_string, to_date_string))
    ).then(
      (data) => {
        data.map(e => e.pop());
        data =  !data[0] ? this.fillDataWithZeros(data) : data;
        this.updateSpeed85Chart(data, this.location_info);
      },
      (err) => console.error(err)
    );
  }

  // data: Array of Array containing items: {timestamp, value}
  fillDataWithZeros(data) {
    // get all unique timestamps in order
    let tsIndexes = [];
    for (var i=0; i<data.length; i++) {
      tsIndexes = tsIndexes.concat(data[i].map(e => e.timestamp));
    }
    tsIndexes = Array.from(new Set(tsIndexes)); //remove duplicates
    tsIndexes.sort((ts1, ts2) => {
      return ts1 - ts2;
    });

    let dataWithZeros = [];
    // iterate over each Array, setting values to 0
    for (var i=0; i<data.length; i++) {
      var d = tsIndexes.map((id) => { return {"timestamp": id, "value": 0} });
      for (var e of data[i]) {
        var idx = tsIndexes.indexOf(e.timestamp);
        if (idx >= 0) {
          d[idx].value = e.value;
        }
      }
      dataWithZeros.push(d);
    }
    return dataWithZeros;
  }

  updateVCChart(data, info) {
    this.VCcanvas = <HTMLCanvasElement>document.getElementById('VCchart');
    if (this.VCcanvas === null) {
      return;
    }
    if (this.VCchart) {
      this.VCchart.destroy();
    }

    //Prepare data for chart, for each zone
    var chartData = {
      labels: data[0].map(x => x.timestamp),
      datasets: [],
    }
    for (var i = 0; i < data.length; i++) {
      let object_grp = data[i];
      let label = this.location_info.zones[i].description;

      chartData.datasets.push({
        label: label,
        data: object_grp.map(x => x.value),
        fill: false,
        backgroundColor: this.getColor()[i],
        borderColor: this.getColor()[i],
        borderWidth: this.borderWidth1
      });
    }

    this.VCchart = new Chart(this.VCcanvas.getContext('2d'), {
      type: 'line',
      data: chartData,

      options: {
        responsive: true,
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: this._allVhcLabel + ' ' + this.getDayLabel(this.date_origin)
        },
        animation: {
          animateScale: false,
          animateRotate: false,
        },

        scales: {
          xAxes: this.xAxes

        },
        //plugins: [this.reduceDataPointsPlugin]

      }
    });

    this.VCTotVehicle = null;
    this.VCTotVehiclePerZone = null;
    this.VCTotVehicle = this.getTotNbVehicleFromData(data);
    this.VCTotVehiclePerZone = this.getTotNbVehiclesPerZoneFromData(data);
    this.VCAvgVehicle = null;
    this.VCAvgVehiclePerZone = null;
    this.VCAvgVehicle = this.getAverageNbVehicleFromData(data);
    this.VCAvgVehiclePerZone = this.getAvgNbVehiclesPerZoneFromData(data);
  }


  updateVC1Chart(data, info) {
    this.VC1canvas = <HTMLCanvasElement>document.getElementById('VC1chart');
    if (this.VC1canvas === null) {
      return;
    }
    if (this.VC1chart) {
      this.VC1chart.destroy();
    }

    //Prepare data for chart, for each zone
    var chartData = {
      labels: data[0].map(x => x.timestamp),
      datasets: [],
    }
    for (var i = 0; i < data.length; i++) {
      let object_grp = data[i];
      let label = this.location_info.zones[i].description;
      //      let label = this.capitalizeFirstLetter(this.location_info.zones[i].zone_name) +
      // " : " + this.location_info.zones[i].description;

      chartData.datasets.push({
        label: label,
        data: object_grp.map(x => x.value),
        fill: false,
        backgroundColor: this.getColor()[i],
        borderColor: this.getColor()[i],
        borderWidth: this.borderWidth1
      });
    }

    this.VC1chart = new Chart(this.VC1canvas.getContext('2d'), {
      type: 'line',
      data: chartData,

      options: {
        responsive: true,
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: this._2wheelersLabel + ' ' + this.getDayLabel(this.date_origin)
        },
        animation: {
          animateScale: false,
          animateRotate: false,
        },

        scales: {
          xAxes: this.xAxes

        },
        //plugins: [this.reduceDataPointsPlugin]

      }
    });

    this.VC1TotVehicle = null;
    this.VC1TotVehiclePerZone = null;
    this.VC1TotVehicle = this.getTotNbVehicleFromData(data);
    this.VC1TotVehiclePerZone = this.getTotNbVehiclesPerZoneFromData(data);
    this.VC1AvgVehicle = null;
    this.VC1AvgVehiclePerZone = null;
    this.VC1AvgVehicle = this.getAverageNbVehicleFromData(data);
    this.VC1AvgVehiclePerZone = this.getAvgNbVehiclesPerZoneFromData(data);
  }

  updateVC2Chart(data, info) {
    this.VC2canvas = <HTMLCanvasElement>document.getElementById('VC2chart');
    if (this.VC2canvas === null) {
      return;
    }
    if (this.VC2chart) {
      this.VC2chart.destroy();
    }

    //Prepare data for chart, for each zone
    var chartData = {
      labels: data[0].map(x => x.timestamp),
      datasets: [],
    }
    for (var i = 0; i < data.length; i++) {
      let object_grp = data[i];
      let label = this.location_info.zones[i].description;
      //      let label = this.capitalizeFirstLetter(this.location_info.zones[i].zone_name) +
      // " : " + this.location_info.zones[i].description;

      chartData.datasets.push({
        label: label,
        data: object_grp.map(x => x.value),
        fill: false,
        backgroundColor: this.getColor()[i],
        borderColor: this.getColor()[i],
        borderWidth: this.borderWidth1
      });
    }

    this.VC2chart = new Chart(this.VC2canvas.getContext('2d'), {
      type: 'line',
      data: chartData,

      options: {
        responsive: true,
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: this._carsLabel + ' ' + this.getDayLabel(this.date_origin)
        },
        animation: {
          animateScale: false,
          animateRotate: false,
        },

        scales: {
          xAxes: this.xAxes

        },
        //plugins: [this.reduceDataPointsPlugin]

      }
    });

    this.VC2TotVehicle = null;
    this.VC2TotVehiclePerZone = null;
    this.VC2TotVehicle = this.getTotNbVehicleFromData(data);
    this.VC2TotVehiclePerZone = this.getTotNbVehiclesPerZoneFromData(data);
    this.VC2AvgVehicle = null;
    this.VC2AvgVehiclePerZone = null;
    this.VC2AvgVehicle = this.getAverageNbVehicleFromData(data);
    this.VC2AvgVehiclePerZone = this.getAvgNbVehiclesPerZoneFromData(data);
  }

  updateVC3Chart(data, info) {
    this.VC3canvas = <HTMLCanvasElement>document.getElementById('VC3chart');
    if (this.VC3canvas === null) {
      return;
    }
    if (this.VC3chart) {
      this.VC3chart.destroy();
    }

    //Prepare data for chart, for each zone
    var chartData = {
      labels: data[0].map(x => x.timestamp),
      datasets: [],
    }
    for (var i = 0; i < data.length; i++) {
      let object_grp = data[i];
      let label = this.location_info.zones[i].description;
      //      let label = this.capitalizeFirstLetter(this.location_info.zones[i].zone_name) +
      // " : " + this.location_info.zones[i].description;

      chartData.datasets.push({
        label: label,
        data: object_grp.map(x => x.value),
        fill: false,
        backgroundColor: this.getColor()[i],
        borderColor: this.getColor()[i],
        borderWidth: this.borderWidth1
      });
    }

    this.VC3chart = new Chart(this.VC3canvas.getContext('2d'), {
      type: 'line',
      data: chartData,

      options: {
        responsive: true,
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: this._trucksLabel + ' ' + this.getDayLabel(this.date_origin)
        },
        animation: {
          animateScale: false,
          animateRotate: false,
        },

        scales: {
          xAxes: this.xAxes

        },
        //plugins: [this.reduceDataPointsPlugin]

      }
    });

    this.VC3TotVehicle = null;
    this.VC3TotVehiclePerZone = null;
    this.VC3TotVehicle = this.getTotNbVehicleFromData(data);
    this.VC3TotVehiclePerZone = this.getTotNbVehiclesPerZoneFromData(data);
    this.VC3AvgVehicle = null;
    this.VC3AvgVehiclePerZone = null;
    this.VC3AvgVehicle = this.getAverageNbVehicleFromData(data);
    this.VC3AvgVehiclePerZone = this.getAvgNbVehiclesPerZoneFromData(data);
  }

  updateSpeedChart(data, info) {
    this.speedCanvas = <HTMLCanvasElement>document.getElementById('speedChart');
    if (!data[0][0]){
      this.isSpeedAvgData = false;
    }
    else {
      this.isSpeedAvgData = true;
      if (this.speedCanvas === null) {
        return;
      }
      if (this.speedChart) {
        this.speedChart.destroy();
      }

      //Prepare data for chart, for each zone
      var chartData = {
        labels: data[0].map(x => x.timestamp),
        datasets: [],
      }
      for (var i = 0; i < data.length; i++) {
        let object_grp = data[i];
        let label = this.location_info.zones[i].description;
        //      let label = this.capitalizeFirstLetter(this.location_info.zones[i].zone_name) +
        // " : " + this.location_info.zones[i].description;

        chartData.datasets.push({
          label: label,
          data: object_grp.map(x => x.value),
          fill: false,
          backgroundColor: this.getColor()[i],
          borderColor: this.getColor()[i],
          steppedLine: 'true',
          showLine: false
        });
      }

      this.speedChart = new Chart(this.speedCanvas.getContext('2d'), {
        type: 'line',
        data: chartData,

        options: {
          responsive: true,
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: this.speedAvgLabel + ': ' + this.getDayLabel(this.date_origin)
          },
          animation: {
            animateScale: false,
            animateRotate: false,
          },
          elements: {
            point: {
              pointStyle: 'rect'
            }
          },
          scales: {
            xAxes: this.xAxes,
          },
          //plugins: [this.reduceDataPointsPlugin]

        }
      })
    }
  }

  updateSpeed85Chart(data, info) {
    this.speed85Canvas = <HTMLCanvasElement>document.getElementById('speed85Chart');
    if (!data[0][0]){
      this.isSpeed85Data = false;
    }
    else {
      this.isSpeed85Data = true;
      if (this.speed85Canvas === null) {
        return;
      }
      if (this.speed85Chart) {
        this.speed85Chart.destroy();
      }

      //Prepare data for chart, for each zone
      var chartData = {
        labels: data[0].map(x => x.timestamp),
        datasets: [],
      }
      for (var i = 0; i < data.length; i++) {
        let object_grp = data[i];
        let label = this.location_info.zones[i].description;
        //      let label = this.capitalizeFirstLetter(this.location_info.zones[i].zone_name) +
        // " : " + this.location_info.zones[i].description;

        chartData.datasets.push({
          label: label,
          data: object_grp.map(x => x.value),
          fill: false,
          backgroundColor: this.getColor()[i],
          borderColor: this.getColor()[i],
          steppedLine: 'true',
          showLine: false
        });
      }

      this.speed85Chart = new Chart(this.speed85Canvas.getContext('2d'), {
        type: 'line',
        data: chartData,

        options: {
          responsive: true,
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: this.speed85Label + ': ' + this.getDayLabel(this.date_origin)
          },
          animation: {
            animateScale: false,
            animateRotate: false,
          },
          elements: {
            point: {
              pointStyle: 'rect'
            }
          },
          scales: {
            xAxes: this.xAxes,
          },
          //plugins: [this.reduceDataPointsPlugin]

        }
      })
    }
  }

  format_date_to_bbdata_format(ngbDate: NgbDate, ngbTime: NgbTimeStruct, endDate: boolean = false) {
    if (!ngbDate) {
      return null;
    }
    // the shenanigan with minutes is just to show the exact minutes like selected
    let shift = 0;
    if (endDate) {
      shift = 14;
    }
    //TODO The Timezone is hardcoded here, consider revising
    const HOUR_SHIT = 1;
    var date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day, ngbTime.hour - HOUR_SHIT, ngbTime.minute + shift, ngbTime.second);
    return dateformat(date, "yyyy-mm-dd") + "T" + dateformat(date, "HH:MM:ss") + "Z";
  }

  format_date_to_chart_format(date: string) {
    if (!date) {
      return null;
    }
    return dateformat(new Date(date), "dd-mm-yyyy HH:MM");
  }


  /* plugin for grouping points in chart, when there are too many of them */
  filterData(chart) {
    var maxRenderedPointsX = 100; //800
    var datasets = chart.data.datasets;
    if (!chart.data.origDatasetsData) {
      chart.data.origDatasetsData = [];
      for (var i in datasets) {
        chart.data.origDatasetsData.push(datasets[i].data);
      }
    }
    var originalDatasetsData = chart.data.origDatasetsData;
    var chartOptions = chart.options.scales.xAxes[0];
    var startX = chartOptions.time.min;
    var endX = chartOptions.time.max;

    if (startX && typeof startX === 'object')
      startX = startX._d.getTime();
    if (endX && typeof endX === 'object')
      endX = endX._d.getTime();

    for (let i: number = 0; i < originalDatasetsData.length; i = i + 1) {
      var originalData = originalDatasetsData[i];

      if (!originalData.length)
        continue;

      var firstElement = { index: 0, time: null };
      var lastElement = { index: originalData.length - 1, time: null };

      for (var j = 0; j < originalData.length; j++) {
        var time = originalData[j].x;
        if (time >= startX && (firstElement.time === null || time < firstElement.time)) {
          firstElement.index = j;
          firstElement.time = time;
        }
        if (time <= endX && (lastElement.time === null || time > lastElement.time)) {
          lastElement.index = j;
          lastElement.time = time;
        }
      }
      var startIndex = firstElement.index <= lastElement.index ? firstElement.index : lastElement.index;
      var endIndex = firstElement.index >= lastElement.index ? firstElement.index : lastElement.index;
      datasets[i].data = this.reduce(originalData.slice(startIndex, endIndex + 1), maxRenderedPointsX);
    }
  }

  // returns a reduced version of the data array, averaging x and y values
  reduce(data, maxCount) {
    if (data.length <= maxCount)
      return data;
    var blockSize = data.length / maxCount;
    var reduced = [];
    for (var i = 0; i < data.length;) {
      var chunk = data.slice(i, (i += blockSize) + 1);
      reduced.push(this.average(chunk));
    }
    return reduced;
  }

  average(chunk) {
    var x = 0;
    var y = 0;
    for (var i = 0; i < chunk.length; i++) {
      x += chunk[i].x;
      y += chunk[i].y;
    }
    return { x: Math.round(x / chunk.length), y: y / chunk.length };
  }

  getColor(opacity = 1.0) { //old 0.4
    return [
      'rgba(255,0,0,' + opacity + ')',
      'rgba(0,0,255,' + opacity + ')',
      'rgba(0,255,0,' + opacity + ')',
      'rgba(255,0,255,' + opacity + ')',
      'rgba(0,255,255,' + opacity + ')',
      'rgba(255,255,0,' + opacity + ')',
    ];
  }


  capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }


  getLocationInfoFromGroupId(group_id) {
    for (let location of this.bbdataService.getAvailableLocations()) {
      if (location.object_group_id == group_id)
        return location;
    }
    return null;
  }

  getTotNbVehicleFromData(data) {

    return data.map(x => x.reduce((acc, cur) => acc + cur.value, 0)).reduce((zone_acc, zone_cur) => zone_acc + zone_cur, 0);

  }

  getTotNbVehiclesPerZoneFromData(data) {
    return data.map(x => x.reduce((acc, cur) => acc + cur.value, 0));
  }

  getAverageNbVehicleFromData(data) {
    let avg_per_zone = data.map(x => x.reduce((acc, cur) => acc + cur.value, 0) / x.length);
    return avg_per_zone.reduce((zone_acc, zone_cur) => zone_acc + zone_cur, 0);
  }

  getAvgNbVehiclesPerZoneFromData(data) {
    return data.map(x => x.reduce((acc, cur) => acc + cur.value, 0) / x.length);
  }

  data2csv(tab, sep = ',') {
    function renameKey ( obj, oldKey, newKey ) {
      obj[newKey] = obj[oldKey];
      delete obj[oldKey];
    }
    //update 'value' header to proper name
    for(let j = 0; j < tab.length; j++ ){
      let new_header = this.location_info['zones'][j]['description'];
      tab[j].forEach(obj => renameKey(obj, 'value', new_header));
      tab[j].forEach(obj => delete obj['objectId']); // remove unwanted objectId property
    }
    var res = tab[0];
    var merge_fct = (obj1, obj2) => ({...obj1, ...obj2});
    for(let j = 1; j < tab.length; j++ ){
      var a = tab[j];
      var tmp = _.zipWith(res, a, merge_fct);
      res = tmp;
    }

    var csv = [];
    //header
    csv.push(this.location_info['city']);
    let row = ['Timestamp'];
    for(let j = 0; j < tab.length; j++ ){
      row.push(this.location_info['zones'][j]['description']);
    }
    csv.push(row.join(sep));
    //data
    for(let i = 0; i < res.length; i++) {
      let row = [];
      for(let key in res[i]) {
        row.push(res[i][key]);
      }
      csv.push(row.join(sep));
    }
    const result = csv.join('\r\n');
    return result;
  }

  downloadCsvAllVehicles() {
    const csv = this.data2csv(this.dataAll);
    const f = new Blob([csv], {type: "text/csv;charset=utf-8"});
    saveAs(f, 'data-all-vehicles.csv');
  }
  downloadCsvTrucks() {
    const csv = this.data2csv(this.dataTrucks);
    const f = new Blob([csv], {type: "text/csv;charset=utf-8"});
    saveAs(f, 'data-trucks.csv');
  }
  downloadCsvCars() {
    const csv = this.data2csv(this.dataCars);
    const f = new Blob([csv], {type: "text/csv;charset=utf-8"});
    saveAs(f, 'data-cars.csv');
  }
  downloadCsv2Wheelers() {
    const csv = this.data2csv(this.data2Wheelers);
    const f = new Blob([csv], {type: "text/csv;charset=utf-8"});
    saveAs(f, 'data-2-wheelers.csv');
  }

  //needed?
  onDateSelectedRequest(event) {
    this.apply_date_by_origin(event);
  }

}
