import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ServerService } from '@app/service/server.service';
import { Document } from '@app/model/document';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit, AfterViewInit {

  //The name for the column of the display array
  displayedColumns: string[] = ['Name', 'MonthOfMeasure', 'YearOfMeasure', 'MarkerID', 'PathToFile', 'Edit', 'Delete'];
  dataSource = new MatTableDataSource<any>();   //Use to populate the array
  action: string;                               //For updating the name of the modal form

  places: any[] = [];                           //Store info about the places
  markers: any[] = [];                          //Store info about the markers
  fd: File;                                     //Store the file given by the file-upload component

  currentDocument: Document = new Document();
  loading: boolean = false;                     //De-activate the loading display

  form: FormGroup;                              //The form in the modal
  modalRef: BsModalRef;                         //Store ref to the modal form
  modalCallback: () => void;                    //Call back for the modal form

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private fb: FormBuilder,
              private modalService: BsModalService,
              private server: ServerService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: this.currentDocument.PlaceID,
      marker : this.currentDocument.MarkerID,
      month: this.currentDocument.MonthOfMeasure,
      year: this.currentDocument.YearOfMeasure,
      document: this.currentDocument.PathToFile,
    });
    this.refresh();
  }

  async refresh() {
    this.loading = true;
    const data = await this.server.getDocuments();    //Get all the documents from the database
    this.places = await this.server.getPlacesName();  //Get the place name and id for select option in form
    this.markers = await this.server.getMarkersByType("pdf");    //Get the markers for select option in form
    this.dataSource.data = data;
    this.loading = false;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  addDoc(template: any) {
    this.action = "Ajouter";
    this.currentDocument = {DocumentID: null, PlaceID: null, Name: null, MonthOfMeasure: null, YearOfMeasure: null, MarkerID: null, PathToFile: null};
    this.updateForm();
    this.modalCallback = this.createDocument.bind(this);
    this.modalRef = this.modalService.show(template);
  }

  async createDocument() {
    //Get values from the form
    const NEW_DOCUMENT = {
      PlaceID: this.form.get('name').value,
      MarkerID: this.form.get('marker').value,
      MonthOfMeasure: this.form.get('month').value,
      YearOfMeasure: this.form.get('year').value
    };

    const place = this.places.find(x => x.PlaceID == NEW_DOCUMENT.PlaceID);

    //Create a form with all the data for saving in database + on file system
    const formData = new FormData();
    formData.append("thumbnail", this.fd);
    formData.append("month", NEW_DOCUMENT.MonthOfMeasure);
    formData.append("year", NEW_DOCUMENT.YearOfMeasure);
    formData.append("place", place.Name);
    formData.append("marker", NEW_DOCUMENT.MarkerID);

    this.modalRef.hide();

    await this.server.createDocument(formData); //Send data to backend    }
    await this.refresh();
  }

  editDocument(doc, template: any) {
    this.action = "Modifier";
    this.currentDocument = doc;
    this.updateForm();
    this.modalCallback = this.updateDocument.bind(this);
    this.modalRef = this.modalService.show(template);
  }

  private updateForm() {
    const place = this.places.find(x => x.Name == this.currentDocument.Name);
    if(this.currentDocument.Name != null)
      this.currentDocument.PlaceID = place.PlaceID;

    this.form.setValue({
      name: this.currentDocument.PlaceID,
      year: this.currentDocument.YearOfMeasure,
      month: this.currentDocument.MonthOfMeasure,
      marker: this.currentDocument.MarkerID,
      document: null
    });
  }

  async updateDocument() {
    const DOC_DATA: Document = {
      DocumentID: this.currentDocument.DocumentID,
      PlaceID: this.form.get('name').value,
      MarkerID: this.form.get('marker').value,
      MonthOfMeasure: this.form.get('month').value,
      YearOfMeasure: this.form.get('year').value
    };
    this.modalRef.hide();
    await this.server.updateDocument(DOC_DATA);
    await this.refresh();
  }

  async deleteDocument(doc: Document) {
    this.loading = true;
    if (confirm(`Are you sure you want to delete the product ${doc.PathToFile}. This cannot be undone.`)) {
      await this.server.deleteDocument(doc.DocumentID);
    }
    await this.refresh();
  }

  //Get param fron child by event
  fileName(fd:File) {
    this.fd = fd;
  }

  onCancel() {
    this.modalRef.hide();
  }

}
