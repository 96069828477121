import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';

const POLLUTION_LINK = "http://pollution.isc.heia-fr.ch";
// const POLLUTION_LINK = "http://localhost:4200";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  pollutionLink;

  constructor(
    public translate: TranslateService,
    private cookieService: CookieService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event) => {
      this.setLangOnPollutionLink(event.lang);
    });
  }

  switchLanguage(lang: string) {
    this.translate.use(lang);
    this.cookieService.set('lang', lang, { path: "/" });
    moment.locale(lang);
    this.setLangOnPollutionLink(lang);
  }

  setLangOnPollutionLink(lang: string) {
    this.pollutionLink = POLLUTION_LINK + "?lang=" + lang;
  }

}
