<mat-toolbar role="heading" color="primary">
  <mat-toolbar-row>
    <a href="/">
      <h1>FriIoTnet</h1>
    </a>
    <span class="mini-spacer"></span>
    <span style="font-size: 12px;">{{ 'HEADER.TITLE' | translate }}</span>
    <span class="fill-spacer"></span>
    <a mat-button *ngIf="!isAuthenticated" [routerLink]="'/login'">
      <mat-icon class="example-icon" *ngIf="!isAuthenticated" aria-hidden="false" aria-label="login">
        login
      </mat-icon>
    </a>
    <a mat-button *ngIf="isAuthenticated" (click)="logout()">
      <mat-icon class="example-icon" *ngIf="isAuthenticated" aria-hidden="false" aria-label="logout">
        logout
      </mat-icon>
    </a>
  </mat-toolbar-row>
</mat-toolbar>

