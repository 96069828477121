import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FileElement } from '@app/model/fileElement';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrls: ['./file-explorer.component.css']
})
export class FileExplorerComponent implements OnInit {

  @Input() fileElements: FileElement[];
  @Input() canNavigateUp: string;
  @Input() path: string;

  @Output() navigatedDown = new EventEmitter<FileElement>();
  @Output() navigatedUp = new EventEmitter();
  @Output() openFile = new EventEmitter<FileElement>();

  constructor(
    private translate : TranslateService
  ) { }

  ngOnInit(): void {
  }

  navigate(element: FileElement) {
    if (element.isFolder) {
      console.log("element", element);
      this.navigatedDown.emit(element);
    } else {
      this.openFile.emit(element);
    }
  }

  navigateUp() {
    this.navigatedUp.emit();
  }

}
