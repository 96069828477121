import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { MapComponent } from './map/map.component';
import { StatComponent } from './stat/stat.component';
import { IavComponent } from './iav/iav.component';
import { DecentLabComponent } from './decent-lab/decent-lab.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { QuickselectorComponent } from './time-filters/quickselector/quickselector.component';
import { NoiseComponent } from './noise/noise.component';
import { LoginComponent } from './login/login.component';
import { AdminMenuComponent } from './admin-menu/admin-menu.component';
import { PlaceComponent } from './place/place.component';
import { MarkerComponent } from './marker/marker.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DocumentComponent } from './document/document.component';
import { FileExplorerComponent } from './file-explorer/file-explorer.component';
import { AdminNoiseComponent } from './admin-noise/admin-noise.component';
import { AdminHomeComponent } from './admin-home/admin-home.component'
import { DensityServiceService } from './service/density-service.service';
import { AdminDensityComponent } from './admin-density/admin-density.component';

import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FlexLayoutModule } from '@angular/flex-layout'
import { PdfViewerModule } from  'ng2-pdf-viewer';
import { CookieService } from 'ngx-cookie-service';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule} from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list'
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {MatNativeDateModule} from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const oktaConfig = {
  issuer: 'https://dev-2101412.okta.com/oauth2/default',
  clientId: '0oa2h3ldjc3asNsey5d7',
  redirectUri: window.location.origin + '/callback'
};

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    StatComponent,
    IavComponent,
    DecentLabComponent,
    HeaderComponent,
    FooterComponent,
    QuickselectorComponent,
    NoiseComponent,
    LoginComponent,
    AdminMenuComponent,
    PlaceComponent,
    MarkerComponent,
    FileUploadComponent,
    DocumentComponent,
    FileExplorerComponent,
    AdminNoiseComponent,
    AdminHomeComponent,
    AdminDensityComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    NgxSpinnerModule,
    AppRoutingModule,
    OktaAuthModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    ModalModule.forRoot(),
    MatToolbarModule,
    MatGridListModule,
    FlexLayoutModule,
    MatMenuModule,
    PdfViewerModule,
    MatCardModule,
    MatSidenavModule,
    MatSortModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    DensityServiceService,
    CookieService,
    { provide: OKTA_CONFIG, useValue: oktaConfig },
    {provide: OverlayContainer, useClass: FullscreenOverlayContainer}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
