import { Component, Inject, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ServerService } from '@app/service/server.service';
import { Place } from '../model/place';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-place',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.css']
})

export class PlaceComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['Name', 'Code', 'Edit', 'Delete'];
  dataSource = new MatTableDataSource<Place>();
  action: string;

  currentPlace: Place = new Place();
  loading: boolean = false;

  form: FormGroup;
  modalRef: BsModalRef;
  modalCallback: () => void;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private fb: FormBuilder,
              private modalService: BsModalService,
              private server: ServerService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.currentPlace.Name, Validators.required],
      code: this.currentPlace.Code,
    });
    this.refresh();
  }

  async refresh() {
    this.loading = true;
    const data = await this.server.getPlaces();
    this.dataSource.data = data;
    this.loading = false;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  addPlace(template: any) {
    this.action = "Ajouter";
    this.currentPlace = {PlaceID: null, Name: '', Code: null};
    this.updateForm();
    this.modalCallback = this.createPlace.bind(this);
    this.modalRef = this.modalService.show(template);
  }

  async createPlace() {
    const NEW_PLACE: Place = {
      Name: this.form.get('name').value,
      Code: this.form.get('code').value,
    };
    this.modalRef.hide();
    await this.server.createPlace(NEW_PLACE);
    await this.refresh();
  }

  editPlace(place: Place, template: any) {
    this.action = "Modifier";
    this.currentPlace = place;
    this.updateForm();
    this.modalCallback = this.updatePlace.bind(this);
    this.modalRef = this.modalService.show(template);
  }

  private updateForm() {
    console.log('currentPlace', this.currentPlace.Name);
    this.form.setValue({
      name: this.currentPlace.Name,
      code: this.currentPlace.Code
    });
  }

  async updatePlace() {
    const PLACE_DATA : Place = {
      PlaceID: this.currentPlace.PlaceID,
      Name: this.form.get('name').value,
      Code: this.form.get('code').value,
    };
    this.modalRef.hide();
    await this.server.updatePlace(PLACE_DATA);
    await this.refresh();
  }

  async deletePlace(place: Place) {
    this.loading = true;
    if (confirm(`Etes-vous certain de vouloir effacer ${place.Name}. Ceci est irrémédiable.`)) {
      await this.server.deletePlace(place);
    }
    await this.refresh();
  }

  onCancel() {
    this.modalRef.hide();
  }

}
