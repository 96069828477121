<div class="container-fluid">
  <section class="col">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      Afficher les densités de trafic
    </div>
    <div class="mat-elevation-z8 margin-bottom-container">
      <table mat-table [dataSource]="dataSource" matSort>
    
        <!-- RoadName Column -->
        <ng-container matColumnDef="RoadName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
          <td mat-cell *matCellDef="let row"> {{row.RoadName}} </td>
        </ng-container>

        <!-- RoadSectionID Column -->
        <ng-container matColumnDef="RoadSectionID">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Section </th>
          <td mat-cell *matCellDef="let row"> {{row.RoadSectionID}}</td>
        </ng-container>
    
        <!-- Lat Column -->
        <ng-container matColumnDef="Year">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Année </th>
          <td mat-cell *matCellDef="let row"> {{row.Year}} </td>
        </ng-container>

        <!-- Lon Column -->
        <ng-container matColumnDef="Density">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Densité </th>
          <td mat-cell *matCellDef="let row"> {{row.Density}} </td>
        </ng-container>

        <!-- Edit Column -->
        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="editDensity(row, densitymodal)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="Delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button class="mib del" mat-icon-button (click)="deleteDensity(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                    showFirstLastButtons 
                    aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
    <div class="col-md-2">
      <button mat-raised-button color="primary" (click)="addDensity(densitymodal)">
        <i class="material-icons">add</i> Ajouter
      </button>
    </div>
  </section>
</div>
<div class="loading" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<ng-template #densitymodal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{action}} une densité</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
      <div class="form-group full-width-input">
        <label>Nom du lieu</label>
        <select class="form-control"
                formControlName="name"
                required>
          <option *ngFor="let road of roads" value="{{road.RoadSectionID}}">{{road.RoadSectionID}} - {{road.RoadName}}</option>
        </select>
      </div>
      <div class="form-group full-width-input">
        <label>Point du lieu</label>
        <input class="form-control"
                placeholder="Year, ex. 2042"
                type="number"
                formControlName="year"
                required>
      </div>
      <div class="form-group full-width-input">
        <label>Densité</label>
        <input class="form-control"
              type="number"
              formControlName="density">
      </div>
      <div class="button-row">
        <button type="button" class="btn btn-primary" (click)="modalCallback()">{{action}}</button>
        <button type="button" class="btn btn-light" (click)="onCancel()">Annuler</button>
      </div>
    </form>
  </div>
</ng-template>
